import React from "react";
import video from "../../assets/images/home-1.mp4";

const MainBannerItems = () => {
  return (
    <>
      <div className="!w-full relative">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="object-cover w-full h-full"
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </>
  );
};

export default MainBannerItems;

