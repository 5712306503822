import { apiRequest } from "../../util/async/apiUtils";


export const adminLoginService = async (
  params,
  setLoaded = () => {},
  setResData = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "login", {
      body: {
        email: params?.email,
        password: params?.password,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};

// export const viewLoginRelation = async (
//   params,
//   setLoaded = () => {},
//   setResData = () => {}
// ) => {
//   setLoaded(false);
//   try {
//     const res = await apiRequest("post", "login-for-admin", {
//       body: {
//         ...params,
//       },
//     });
//     const data = res.data;
//     setResData(data);
//     setLoaded(true);
//     return res;
//   } catch (error) {
//     setResData(error);
//     setLoaded(true);
//     return error;
//   }
// };
