import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import Table from "../../../components/elements/table/Table";
import { getCompanyList } from "../../../services/adminService/UserService";
import { useDebounce } from "../../../helpers/useDebounce";

const Company = () => {
  const [limit, setLimit] = useState("");
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [sort, setSort] = useState("-createdAt");
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 300);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);

  // loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));
    const statusData = {};
    if (status && Array.isArray(status) && status?.length > 0) {
      status?.forEach((item, i) => (statusData[`status[${i}]`] = item));
    }
    getCompanyList({
      sortQuery: sort,
      keyword: search,
      limit: 10,
      page: currentPage,
      ...statusData,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          keyword: keyword,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setCount(res?.docs?.metadata?.totalDocs);
      } else {
        setList((pre) => ({ ...pre, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [limit, status, sort, featured, search, currentPage, count]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Pagination Logic
  // useEffect(() => {
  //   if (list?.data?.length <= 0 && pagination?.hasNextPage) {
  //     setCurrentPage(currentPage);
  //   } else if (list?.data?.length <= 0 && !pagination?.hasPrevPage) {
  //     setCurrentPage(1);
  //   } else if (list?.data?.length <= 0) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }, [list?.data.length]);

  //Refresh Function

  //Table Heading Data >>>>>>>>>

  const tableHeadData = [
    {
      _id: 1,
      name: "Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-name");
        } else {
          setSort("name");
        }
      },
    },
    {
      _id: 2,
      name: "Email",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    // {
    //   _id: 3,
    //   name: "Status",
    //   align: "left",
    //   isFilter: true,
    //   isFilterSearch: false,
    //   isSort: false,
    //   filterData: statusFilterData,
    //   onFilter: (data) => {
    //     setStatus(data?.map((item) => item?.value));
    //     setCurrentPage(1);
    //     setSelectedItems([]);
    //   },
    // },
    // {
    //   _id: 4,
    //   name: "Email Verified",
    //   align: "left",
    //   isFilter: true,
    //   isFilterSearch: false,
    //   isSort: false,
    //   filterData: statusFilterData,
    //   onFilter: (data) => {
    //     setStatus(data?.map((item) => item?.value));
    //     setCurrentPage(1);
    //   },
    // },
    {
      _id: 5,
      name: "Created At",
      align: "left",
      isFilter: false,
      isSort: false,
    },
    // {
    //   _id: 6,
    //   name: "Action",
    //   align: "left",
    //   isFilter: false,
    //   isSort: false,
    // },
  ];

  // Status Change Api Call >>>>>>>>>>

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item, index) => ({
    _id: item?._id,
    rowData: [
      {
        _id: 1,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.name || "N/A",
        image: item?.image?.url,
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.email || "N/A",
      },
      //   {
      //     _id: 3,
      //     type: "status",
      //     align: "left",
      //     cellClass: "!min-w-[100px]",
      //     statusLabel: item?.status === "active" ? "Active" : "In Active",
      //     statusType: item?.status === "active" ? "success" : "danger",
      //     functions: () => handleStatusChange(item),
      //   },
      //   {
      //     _id: 4,
      //     type: "status",
      //     align: "left",
      //     cellClass: "!min-w-[100px]",
      //     statusType: item?.emailVerified === 1 ? "info" : "warning",
      //     statusLabel: item?.emailVerified === 1 ? "Verified" : "Not Verified",
      //   },
      {
        _id: 5,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.createdAt ? moment(item?.createdAt).format("MM-DD-YYYY") : "N/A",
      },
      //   {
      //     _id: 6,
      //     type: "action",
      //     align: "left",
      //     cellClass: "w-[5%]",
      //     actionData: [
      //       {
      //         _id: 1,
      //         name: "Edit",
      //         icon: "fa-regular fa-pen-to-square",
      //         standout: false,
      //         onClick: () => editData(item?._id),
      //       },
      //       {
      //         _id: 2,
      //         name: "View Details",
      //         icon: "fa-regular fa-eye",
      //         standout: false,
      //         link: `/admin/company/details/${item._id}`,
      //       },
      //     ],
      //   },
    ],
  }));

  return (
    <>
      <div className="w-full py-4 px-6">
        <div className="-mx-8">
          <Table
            tableHeadData={tableHeadData}
            tableData={tableData}
            containerClasses={"px-6 min-h-[calc(100vh-136px)]"}
            isLoder={list?.loading}
            isOrderingDisabled={true}
            originalData={[...list.data]}
            // handleOrdering={handleOrdering}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            isCheckbox={false}
            setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
          />
        </div>
      </div>
    </>
  );
};

export default Company;
