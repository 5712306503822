import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
// import AuthLayout from "./layouts/AuthLayout";
// import ProtectedRoute from "./routes/ProtectedRoute";

import DefaultLayout from "./layouts/DefaultLayout";
import InnerLayout from "./layouts/InnerLayout";
// import Signin from "./pages/Signin";
import Home from "./pages/Home";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import PageNotFound from "./components/extra/PageNotFound";
// import ForgotPassword from "./pages/ForgotPassword";
import AdminList from "./pages/admin/admins/AdminList";
import SigninAdmin from "./pages/admin/adminSignIn/SigninAdmin";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import Dashboard from "./pages/admin/dashboard/Dashboard";
import AddEditAdmin from "./pages/admin/admins/AddEditAdmin";
import CompanyList from "./pages/admin/company/CompanyList";
import AddEditCompany from "./pages/admin/company/AddEditCompany";
import EmployeeList from "./pages/admin/employee/EmployeeList";
import AddEditEmployee from "./pages/admin/employee/AddEditEmployee";
import PagesList from "./pages/admin/pages/PagesList";
import CompanyAdminList from "./pages/admin/companyAdmin/CompanyAdminList";
import SiteSetting from "./pages/admin/settings/SiteSetting";
import CompanyAdminAddEdit from "./pages/admin/companyAdmin/CompanyAdminAddEdit";
import Details from "./pages/admin/company/companyDetails/Details";
// import AdminChart from "./pages/AdminChart";
import PositionsList from "./pages/admin/position/PositionsList";

const CreateRoutes = () => {
  // const role = localStorage.getItem("role");
  // const profile = useSelector((state) => state.profile.profileData);
  // console.log(profile, "profilee");
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route path="/signin">
            <Route
              path="/signin"
              exact
              element={
                <FreeAuthRoute title={"Sign In"}>
                  <Signin />
                </FreeAuthRoute>
              }
            />
          </Route> */}
          <Route path="/admin">
            <Route
              path="/admin"
              exact
              element={
                <FreeAuthRoute title={"Admin Login"}>
                  <SigninAdmin />
                </FreeAuthRoute>
              }
            />
          </Route>
          {/* <Route path="/forgotPassword">
            <Route
              path="/forgotPassword"
              exact
              element={
                <FreeAuthRoute title={"Forgot Password"}>
                  <ForgotPassword />
                </FreeAuthRoute>
              }
            />
          </Route> */}

          {/* {/ Admin Routing /} */}
          <Route path="/admin/dashboard" element={<InnerLayout />}>
            <Route
              path="/admin/dashboard"
              exact
              element={
                <ProtectedRoute
                  title={"Admin Dashboard"}
                  getAccess={["superadmin"]}
                >
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/adminList" element={<InnerLayout />}>
            <Route
              path="/admin/adminList"
              exact
              element={
                <ProtectedRoute title={"Admin List"} getAccess={["superadmin"]}>
                  <AdminList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/adminAdd" element={<InnerLayout />}>
            <Route
              path="/admin/adminAdd"
              exact
              element={
                <ProtectedRoute title={"Admin Add"} getAccess={["superadmin"]}>
                  <AddEditAdmin />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/companies" element={<InnerLayout />}>
            <Route
              path="/admin/companies"
              exact
              element={
                <ProtectedRoute
                  title={"Company List"}
                  getAccess={["superadmin"]}
                >
                  <CompanyList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/company/add" element={<InnerLayout />}>
            <Route
              path="/admin/company/add"
              exact
              element={
                <ProtectedRoute
                  title={"Company Add"}
                  getAccess={["superadmin"]}
                >
                  <AddEditCompany />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/company/edit/:id" element={<InnerLayout />}>
            <Route
              path="/admin/company/edit/:id"
              exact
              element={
                <ProtectedRoute
                  title={"Company Edit"}
                  getAccess={["superadmin"]}
                >
                  <AddEditCompany />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/company/details/:id" element={<InnerLayout />}>
            <Route
              path="/admin/company/details/:id"
              exact
              element={
                <ProtectedRoute
                  title={"Company details"}
                  getAccess={["superadmin"]}
                >
                  <Details />
                </ProtectedRoute>
              }
            />
          </Route>
          {/* <Route path="/adminChart/:id" element={<InnerLayout />}>
            <Route
              path="/adminChart/:id"
              exact
              element={
                <ProtectedRoute title={"View Chart"} getAccess={["superadmin"]}>
                  <AdminChart />
                </ProtectedRoute>
              }
            />
          </Route> */}
          <Route path="/admin/employees" element={<InnerLayout />}>
            <Route
              path="/admin/employees"
              exact
              element={
                <ProtectedRoute
                  title={"Employee List"}
                  getAccess={["superadmin"]}
                >
                  <EmployeeList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/employee/add" element={<InnerLayout />}>
            <Route
              path="/admin/employee/add"
              exact
              element={
                <ProtectedRoute
                  title={"employee Add"}
                  getAccess={["superadmin"]}
                >
                  <AddEditEmployee />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/employee/edit/:id" element={<InnerLayout />}>
            <Route
              path="/admin/employee/edit/:id"
              exact
              element={
                <ProtectedRoute
                  title={"employee Edit"}
                  getAccess={["superadmin"]}
                >
                  <AddEditEmployee />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/administrator" element={<InnerLayout />}>
            <Route
              path="/admin/administrator"
              exact
              element={
                <ProtectedRoute
                  title={"Administrator List"}
                  getAccess={["superadmin"]}
                >
                  <CompanyAdminList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/administrator/add" element={<InnerLayout />}>
            <Route
              path="/admin/administrator/add"
              exact
              element={
                <ProtectedRoute
                  title={"administrator Add"}
                  getAccess={["superadmin"]}
                >
                  <CompanyAdminAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/administrator/edit/:id" element={<InnerLayout />}>
            <Route
              path="/admin/administrator/edit/:id"
              exact
              element={
                <ProtectedRoute
                  title={"administrator Edit"}
                  getAccess={["superadmin"]}
                >
                  <CompanyAdminAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/position" element={<InnerLayout />}>
            <Route
              path="/admin/position"
              exact
              element={
                <ProtectedRoute title={"Admin List"} getAccess={["superadmin"]}>
                  <PositionsList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/pages" element={<InnerLayout />}>
            <Route
              path="/admin/pages"
              exact
              element={
                <ProtectedRoute title={"Page List"} getAccess={["superadmin"]}>
                  <PagesList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/settings" element={<InnerLayout />}>
            <Route
              path="/admin/settings"
              exact
              element={
                <ProtectedRoute
                  title={"Site Setting"}
                  getAccess={["superadmin"]}
                >
                  <SiteSetting />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* {/ Front routing /} */}
          <Route
            path="/"
             element={<DefaultLayout isSearch={true} />}
          >
            <Route
              path="/"
              exact
              element={
                // <ProtectedRoute getAccess={["admin"]}>
                <Home />
                // </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<DefaultLayout />}>
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
