import React, { useEffect, useState } from "react";
import SidebarMenuBlocks from "../components/navigation/SidebarMenuBlocks";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../assets/mylogo.png";

// import { getRole } from "../utils/function";
// import { roleCode } from "../utils/enum";

const DefaultSidebar = ({ isMenuOpen, settings }) => {
  //   const { user } = useSelector((state) => state.user);
  const [sideBar, setSidebar] = useState([]);

  const adminSidebarMenu = [
    {
      _id: "1",
      menuTitle: "Statistics & Analytics",
      menuData: [
        {
          _id: 1,
          menuLabel: "Dashboard",
          menuLink: "/admin/dashboard",
          menuIcon: "fa-grid-2",
          menuIconColor: "text-rose-500",
        },
      ],
    },
    {
      _id: "2",
      menuTitle: "User Management",
      menuData: [
        {
          _id: 1,
          menuLabel: "Super Admins",
          menuLink: "/admin/adminList",
          menuIcon: "fa-solid fa-user-secret",
          menuIconColor: "text-amber-500",
        },
        {
          _id: 2,
          menuLabel: "Companies",
          menuLink: "/admin/companies",
          menuIcon: "fa-solid fa-building",
          menuIconColor: "text-sky-500",
        },
        {
          _id: 3,
          menuLabel: "Company Administrator",
          menuLink: "/admin/administrator",
          menuIcon: "fa-solid fa-user-tie",
          menuIconColor: "text-sky-500",
        },
        {
          _id: 4,
          menuLabel: "Employees",
          menuLink: "/admin/employees",
          menuIcon: "fa-solid fa-users-medical",
          menuIconColor: "text-fuchsia-500",
        },
        {
          _id: 5,
          menuLabel: "Positions",
          menuLink: "/admin/position",
          menuIcon: "fa-solid fa-id-card",
          menuIconColor: "text-fuchsia-500",
        },
      ],
    },
    {
      _id: "5",
      menuTitle: "Page Managements",
      menuData: [
        {
          _id: 1,
          menuLabel: "Pages",
          menuLink: "/admin/pages",
          menuIcon: "fa-light fa-memo",
          menuIconColor: "text-green-500",
        },
      ],
    },
    {
      _id: "6",
      menuTitle: "Content Management",
      menuData: [
        // {
        //   _id: crypto.randomUUID(),
        //   menuLabel: "Email Template",
        //   menuLink: "/admin/email-template",
        //   menuIcon: "fa-solid fa-envelope",
        //   menuIconColor: "text-rose-500",
        // },
        {
          _id: 1,
          menuLabel: "Site Settings",
          menuLink: "/admin/settings",
          menuIcon: "fa-gear",
          menuIconColor: "text-amber-500",
        },
      ],
    },
  ];

  //   useEffect(() => {
  //     if (getRole(user?.role, roleCode?.admin)) {
  //       setSidebar([...adminSidebarMenu]);
  //     } else if (getRole(user?.role, roleCode?.company_administrator)) {
  //       setSidebar([...administratorSidebarMenu]);
  //     } else if (getRole(user?.role, roleCode?.company_manager)) {
  //       setSidebar([...managerSidebarMenu]);
  //     }
  //   }, [user]);
  //   !siteSetting ? "animate-pulse bg-gray-300 " :
  return (
    <>
      <div className="relative w-full bg-slate-900 divide-y divide-slate-800 pt-16 lg:pt-0">
        <div
          className={
            `relative flex items-center gap-4 h-16 transition-all duration-100 ${null} ` +
            (isMenuOpen ? "px-3" : "px-4")
          }
        >
          <Link className="w-10 h-10 min-w-[40px] " to={"/"} replace>
            <img className="w-full h-full object-contain rounded-full" src={settings?.logo} alt={"Logo"} />
          </Link>

          {!isMenuOpen && (
            <Link className="w-full" to={"/"} replace>
              <div className="text-lg text-white font-bold leading-tight">{settings?.title}</div>
            </Link>
          )}
        </div>
        <div
          className={
            "relative overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100%-4rem)] transition-all duration-100 divide-y " +
            (isMenuOpen ? " divide-slate-800" : "divide-transparent")
          }
        >
          {Array.isArray(sideBar) &&
            adminSidebarMenu.map((item, index) => (
              <SidebarMenuBlocks
                key={item._id}
                menuTitle={item.menuTitle}
                menuData={item.menuData}
                isMenuOpen={isMenuOpen}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default DefaultSidebar;
