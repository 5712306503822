import React, { useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { loadSiteSettingsDetails, updateSetting } from "../../../services/SettingService";
import TextEditor from "../../../components/form/Editor";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSettings } from "../../../store/siteSettings/settingsSlice";

const Sitesetting = () => {
  const validation = {
    title: { required: true, message: "Please enter your name" },
    email: { required: true, message: "Please enter your email" },
    phone: { required: true, message: "Please enter your designation" },
    address1: { required: true, message: "Please enter your phone no" },
    address2: { required: true, message: "Please enter your phone no" },
    city: { required: true, message: "Please enter your phone no" },
    state: { required: true, message: "Please enter your phone no" },
    country: { required: true, message: "Please enter your phone no" },
    zipcode: { required: true, message: "Please enter your phone no" },
  };
  const { values, handleChange, handleSubmit, setFieldsValue, errors } = useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");
  const [faviconUrl, setFaviconUrl] = useState("");

  const [details, setDetails] = useState({});
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();
  //   const settings = useSelector((state) => state?.settings?.data);

  useEffect(() => {
    if (details) {
      setFieldsValue({
        title: details?.title || "",
        email: details?.email || "",
        phone: details?.phone || "",
        address1: details?.address?.address1 || "",
        address2: details?.address?.address2 || "",
        city: details?.address?.city || "",
        state: details?.address?.state || "",
        country: details?.address?.country || "",
        zipcode: details?.address?.zipcode || "",
        description: details?.description || "",
      });
      setImageUrl(details?.logo);
      setFaviconUrl(details?.favicon);
    }
  }, [details]);

  const onSubmit = (values) => {
    updateSetting({
      title: values?.title,
      email: values?.email,
      phone: values?.phone,
      description: values?.description,
      address: {
        address1: values?.address1,
        address2: values?.address2,
        city: values?.city,
        state: values?.state,
        zipcode: values?.zipcode,
        country: values?.country,
      },
      logo: imageUrl,
      favicon: faviconUrl,
    })
      .then((res) => {
        const data = res.data.data;
        if (res?.data?.status === 200) {
          dispatch(getSettings(res?.data?.data));
          toast.success(res?.data?.message);
        } else if (res?.data?.status === 400) {
          toast.success(res?.data?.message);
        } else {
          toast.info(res?.data?.error);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    loadSiteSettingsDetails(setDetails, setLoaded);
  }, []);

  return (
    <div className="relative">
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="w-full bg-white rounded-md shadow overflow-hidden py-5 px-4 pb-4">
          <div className="flex justify-between items-center border-b border-slate-200">
            <h1 className="flex justify-start text-3xl font-semibold text-victoria py-2">Site Setting</h1>
            <div className="flex justify-end">
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-org-theme !text-org-silver"}
                buttonLabel={"Save"}
              />
            </div>
          </div>
          <div className="divide-y divide-slate-200">
            <div className="relative py-8">
              <div className="w-full pl-8 pr-8">
                <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
                  <div className="col-span-3">
                    <div className="grid grid-cols-12 gap-4">
                      <div className="relative col-span-12">
                        <Input
                          isInputGroup={false}
                          label={"Title"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.title}
                          inputName={"title"}
                          onChange={handleChange}
                          {...formValidate(errors, "title")}
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"Email Id"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.email}
                          inputName={"email"}
                          readOnly
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"Phone No"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.phone}
                          inputName={"phone"}
                          onChange={handleChange}
                          {...formValidate(errors, "phone")}
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"Address 1"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.address1}
                          inputName={"address1"}
                          onChange={handleChange}
                          {...formValidate(errors, "address1")}
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"Address 2"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.address2}
                          inputName={"address2"}
                          onChange={handleChange}
                          {...formValidate(errors, "address2")}
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"City"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.city}
                          inputName={"city"}
                          onChange={handleChange}
                          {...formValidate(errors, "city")}
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"State"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.state}
                          inputName={"state"}
                          onChange={handleChange}
                          {...formValidate(errors, "state")}
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"Country"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.country}
                          inputName={"country"}
                          onChange={handleChange}
                          {...formValidate(errors, "country")}
                        />
                      </div>
                      <div className="relative col-span-6">
                        <Input
                          isInputGroup={false}
                          label={"Zipcode"}
                          labelClasses={"!text-base"}
                          inputType={"text"}
                          value={values.zipcode}
                          inputName={"zipcode"}
                          onChange={handleChange}
                          {...formValidate(errors, "zipcode")}
                        />
                      </div>
                      <div className="relative col-span-12">
                        <TextEditor
                          label="Description"
                          value={values?.description}
                          // onChange={handleTextChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 pl-4">
                    <div className="relative">
                      <SingleImageUpload
                        label={"Logo"}
                        size={"lg"}
                        image={imageUrl}
                        setImage={setImageUrl}
                        accept={["jpg", "png", "jpeg"]}
                      />
                    </div>
                    <div className="relative pt-4">
                      <SingleImageUpload
                        label={"Favicon"}
                        size={"lg"}
                        image={faviconUrl}
                        setImage={setFaviconUrl}
                        accept={["jpg", "png", "jpeg"]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Sitesetting;
