import React from "react";
import MainBanner from "../components/pagescomponnet/MainBanner";
import HomeAbout from "../components/pagescomponnet/HomeAbout";


const Home = () => {
  return (
    <div>
      <MainBanner />
      <HomeAbout></HomeAbout>
    </div>
  );
};

export default Home;
