import React, { useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import { formValidate } from "../../../helpers/formValidate";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { addEditPosition } from "../../../services/adminService/PositionService";

const AddEditPosition = ({
  setIsModalOpen = () => {},
  positionDetails,
  setPositionDetails = () => {},
  setCount = () => {},
}) => {
  const validation = {
    title: { required: true, message: "Please enter title" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (positionDetails?._id) {
      setFieldsValue({ title: positionDetails?.title });
    }
  }, [positionDetails?._id]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.title)) {
        toast.error("Title cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }

      const res = await addEditPosition({
        id: positionDetails?._id,
        title: values?.title,
      });
      if (res?.data?.success) {
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          toast.success(res?.data?.message);
          setIsModalOpen(false);
          setPositionDetails({});
          setCount((pre) => pre + 1);
        } else {
          toast.info(res?.data?.message);
        }
      } else {
        toast.error(res?.data?.error);
        setIsModalOpen(false);
        setPositionDetails({});
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div className="w-full relative">
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="grid  gap-4">
          <div className="col-span-3">
            <div className="grid grid-cols-12 gap-2">
              <div className="relative col-span-12">
                <Input
                  label={"Title"}
                  inputType={"text"}
                  inputPlaceholder={"Enter Title"}
                  inputName={"title"}
                  value={values?.title}
                  onChange={handleChange}
                  {...formValidate(errors, "title")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-4 border-t border-slate-200">
          <div className="flex items-center justify-end space-x-3">
            <Button
              buttonHasLink={false}
              buttonType={"button"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonLabel={"Cancel"}
              buttonClasses={"!bg-white !text-org-theme"}
              buttonFunction={() => {
                setIsModalOpen(false);
                setPositionDetails({});
              }}
            />
            <Button
              buttonHasLink={false}
              buttonType={"submit"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonClasses={"text-white"}
              buttonIconPosition={"left"}
              buttonLabel={"Save"}
              isDisable={loaded ? true : false}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditPosition;
