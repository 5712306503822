import React, { useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Select from "../../../components/form/Select";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import { Validation } from "../../../helpers/Validation";
import useForm from "../../../hooks/useForm";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { toast } from "react-toastify";
import {
  getProfileDetails,
  updateProfile,
} from "../../../services/ProfileService";

const AddEditAdmin = () => {
  const validation = {
    name: { required: true, message: "Please enter your name" },
    email: { required: true, message: "Please enter your email" },
    designation: { required: true, message: "Please enter your designation" },
    // phone: { required: true, message: "Please enter your phone no" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");
  const [resData, setResData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState();

  const handleDateChange = (value) => {
    setDate(value?.startDate);
  };

  const onSubmit = (values) => {
    if (!Validation("name", values.name)) {
      toast.error("Name cannot be empty or contain only spaces.");
      setLoaded(false);
      return;
    }
    if (!Validation("email", values.email)) {
      toast.error("Invalid email format. Please enter a valid email.");
      setLoaded(false);
      return;
    }
    // if (!Validation("phone", values.phone)) {
    //   toast.error(
    //     "Invalid phone number format. Please enter a valid phone number."
    //   );
    //   return;
    // }

    // console.log(values, "jhdhsh");

    //   updateProfile({
    //     name: values?.name,
    //     email: values?.email,
    //     image: imageUrl,
    //   })
    //     .then((res) => {
    //       const data = res.data.data;
    //       if (res?.data?.success) {
    //         toast.success(res?.data?.message);
    //       } else {
    //         toast.error(res?.data?.error);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       toast.error("An error occurred while updating the profile.");
    //     });
  };
  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={"Add Admin"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/adminList"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-org-theme"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-org-theme !text-org-silver"}
                buttonLabel={"Save"}
                // isDisable={loading || formDetails?.loading}
                // loading={loading || formDetails?.loading}
              />
            </div>
          )}
        />
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-pen-nib"}
                        inputGroupPosition={"left"}
                        label={"Designation"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.designation}
                        inputName={"designation"}
                        onChange={handleChange}
                        {...formValidate(errors, "designation")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <DatePickerInput
                        label="Date Of Birth"
                        asSingle={true}
                        useRange={false}
                        value={date}
                        onChange={handleDateChange}
                        showShortcuts={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4">
                <div className="relative">
                  <SingleImageUpload
                    label={"User Image"}
                    size={"lg"}
                    fileType={"image"}
                    selectedFile={imageUrl}
                    setSelectedFile={setImageUrl}
                    selectType={"single"}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/adminList"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-electric-yellow"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  //   isDisable={loading || formDetails?.loading}
                  //   loading={loading || formDetails?.loading}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditAdmin;
