import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Select from "../../../components/form/Select";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import { Validation } from "../../../helpers/Validation";
import useForm from "../../../hooks/useForm";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { toast } from "react-toastify";
import { getProfileDetails, updateProfile } from "../../../services/ProfileService";
import {
  addCompany,
  addEmployee,
  companyDetails,
  employeeDetails,
  getAdminList,
  getCompanyList,
  getReportList,
} from "../../../services/adminService/UserService";
import { useNavigate, useParams } from "react-router-dom";
import SelectDropdownSearch from "../../../components/form/SelectDropdownSearch";
import { getPositionList } from "../../../services/adminService/PositionService";

const CompanyAdminAddEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const validation = {
    name: { required: true, message: "Please enter administrator name" },
    email: { required: true, message: "Please enter administrator email" },
    designation: {
      required: true,
      message: "Please enter administrator title",
    },
    reportTo: { required: true, message: "Please Select Report to" },
    company: { required: true, message: "Please select a company" },
    password: {
      required: id ? false : true,
      message: "Please enter your administrator password",
    },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");
  const [resData, setResData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState();
  const [key, setKey] = useState("");
  const [reportList, setReportList] = useState([]);
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [selectedUserValue, setSelectedUserValue] = useState({});
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [positionKeyword, setPositionKeyword] = useState("");
  const [positionList, setPositionList] = useState([]);

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      employeeDetails({ id, roleCode: "employee" }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          const companyData =
            res?.docs?.companyDetail && res?.docs?.companyDetail.length > 0 && res?.docs?.companyDetail[0];
          setFieldsValue({
            name: res?.docs?.name || "",
            email: res?.docs?.email || "",
            phone: res?.docs?.phone || "",
            designation: res?.docs?.designation || "",
            image: res?.docs?.image?.url,
            company: companyData?._id,
            employeeId: res?.docs?.employeeId,
            reportTo: res?.docs?.reportToField?._id,
            positionId: res?.docs?.positionDetail?._id,
          });
          setImageUrl(res?.docs?.image?.url);
          setDate({
            startDate: res?.docs?.dateOfBirth,
            endDate: res?.docs?.dateOfBirth,
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  // loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    getCompanyList({
      keyword: keyword,
      limit: 999999999,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList(res?.docs?.data);
        setCount(res?.docs?.metadata?.totalDocs);
      } else {
        toast.error(res?.message);
      }
    });
  }, [limit, keyword]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const loadReportList = useCallback(() => {
    if (values?.company) {
      getReportList({
        keyword: key,
        limit: 999999999,
        companyId: values?.company || "",
        roleCode: "admin",
      }).then((res) => {
        if (res && res?.docs?.success) {
          setReportList(res?.docs?.data);
          setCount(res?.docs?.metadata?.totalDocs);
        }
      });
    }
  }, [limit, key, values?.company]);

  useEffect(() => {
    loadReportList();
  }, [loadReportList]);

  const loadPositionList = useCallback(() => {
    getPositionList({
      keyword: positionKeyword,
      limit: 99999,
      status: "active",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setPositionList(res?.docs?.data);
      } else {
        toast.error(res?.message);
      }
    });
  }, [positionKeyword]);

  useEffect(() => {
    loadPositionList();
  }, [loadPositionList]);

  const handleDateChange = (value) => {
    setDate(value?.startDate);
  };

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?).");
    }

    return errors;
  };

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.name)) {
        toast.error("Name cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoaded(false);
        return;
      }
      if (values.password) {
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }
      if (values.password && !Validation("confirmPassword", values.confirmPassword, values.password)) {
        toast.error("Confirm password and password doesn`t match");
        setLoaded(false);
        return;
      }

      // const res = await addEmployee({
      //   id: id,
      //   employeeId: values?.employeeId,
      //   image: imageUrl,
      //   name: values?.name,
      //   email: values?.email,
      //   phone: values?.phone,
      //   // designation: values?.designation,
      //   dateOfBirth: date?.startDate,
      //   roleCode: "admin",
      //   positionId: values?.positionId,
      //   companyId: values?.company,
      //   reportTo: values?.reportTo,
      //   password: values?.password,
      //   confirmPassword: values.confirmPassword,
      // });
      // if (res?.data?.success) {
      //   toast.info(res?.data?.message);
      //   if (res?.data?.status === 200 || res?.data?.status === 201) {
      //     navigate("/admin/administrator");
      //   }
      // } else {
      //   toast.error(res?.data?.error || res?.data?.message);
      // }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  //   console.log(selectedUserValue, "selectedUserValue");
  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={id ? `Edit Administrator` : "Add Administrator"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/administrator"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-org-theme"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-org-theme !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded ? true : false}
              />
            </div>
          )}
        />
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-id-card"}
                        inputGroupPosition={"left"}
                        label={"Administrator Id"}
                        inputPlaceholder={"Enter Administrator Id"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.employeeId}
                        inputName={"employeeId"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        inputPlaceholder={"Enter Administrator Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        inputPlaceholder={"Enter Administrator Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    {/* <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-sharp fa-solid fa-clipboard-user"}
                        inputGroupPosition={"left"}
                        label={"Title"}
                        inputPlaceholder={"Enter Administrator Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.designation}
                        inputName={"designation"}
                        onChange={handleChange}
                        {...formValidate(errors, "designation")}
                      />
                    </div> */}
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Title"}
                        placeholder={"Select a Title"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"positionId"}
                        isSearch={true}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setPositionKeyword(key)}
                        value={values?.positionId}
                        dropdownData={positionList?.map((item) => ({
                          name: item?.title,
                          _id: item?._id,
                        }))}
                        {...formValidate(errors, "positionId")}
                      />
                    </div>

                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Company Name"}
                        placeholder={"Select a company"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"company"}
                        isSearch={true}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setKeyword(key)}
                        value={values?.company}
                        dropdownData={list?.map((item) => ({
                          name: item?.name,
                          email: item?.email,
                          _id: item?._id,
                        }))}
                        {...formValidate(errors, "company")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Report To"}
                        placeholder={"Select Employee"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"reportTo"}
                        isSearch={true}
                        onSearch={(key) => setKey(key)}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        value={values?.reportTo}
                        isDisable={true}
                        dropdownData={reportList?.map((item) => ({
                          name: item?.name,
                          _id: item?._id,
                        }))}
                        // dropdownData={list}
                        {...formValidate(errors, "reportTo")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-phone"}
                        inputGroupPosition={"left"}
                        label={"Phone No"}
                        inputPlaceholder={"Enter Administrator Phone No"}
                        labelClasses={"!text-base"}
                        inputType={"number"}
                        value={values.phone}
                        inputName={"phone"}
                        onChange={handleChange}
                        {...formValidate(errors, "phone")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <DatePickerInput
                        label="Date Of Birth"
                        asSingle={true}
                        useRange={false}
                        value={date}
                        onChange={(val) => setDate(val)}
                        showShortcuts={false}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Password"}
                        inputPlaceholder={"Enter  Password"}
                        labelClasses={"!text-base"}
                        inputType={"password"}
                        value={values.password}
                        inputName={"password"}
                        onChange={handleChange}
                        {...formValidate(errors, "password")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Confirm Password"}
                        inputPlaceholder={"Enter Confirm Password"}
                        labelClasses={"!text-base"}
                        inputType={"confirmPassword"}
                        value={values.confirmPassword}
                        inputName={"confirmPassword"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4">
                <div className="relative">
                  <SingleImageUpload
                    label={"Company Image"}
                    size={"lg"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/administrator"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-org-theme"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  isDisable={loaded ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CompanyAdminAddEdit;
