import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultSidebar from "../shared/DefaultSidebar";
import DefaultHeader from "../shared/DefaultHeader";
import { useDispatch, useSelector } from "react-redux";
import { setProfileDetails } from "../store/Profile/profileSlice";
import { loadProfileDetails } from "../services/ProfileService";
import { loadSiteSettingsDetails } from "../services/SettingService";
import { getSettings } from "../store/siteSettings/settingsSlice";

const InnerLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profileData);
  const settings = useSelector((state) => state?.settings?.data);
  const token = localStorage.getItem("auth_token");
  const [profileData, setprofileData] = useState(null);
  const [loaded, setloaded] = useState(true);
  const [topics, settopics] = useState([]);

  useEffect(() => {
    loadProfileDetails(setprofileData, setloaded).then((res) => {
      if (res?.data?.success) {
        setprofileData(res?.data?.docs);
        dispatch(setProfileDetails(res?.data?.docs));
      }
    });
  }, []);

  // function favIconChange() {
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement("link");
  //     link.rel = "icon";
  //     document.getElementsByTagName("head")[0].appendChild(link);
  //   }
  //   link.href = settings?.favicon?.url;
  // }
  // favIconChange();

  useEffect(() => {
    loadSiteSettingsDetails().then((res) => {
      if (res?.data?.status === 200) {
        dispatch(getSettings(res.data.data));
      }
    });
  }, []);

  const loggedInUser = {
    name: profile && profile?.name,
    image: profile && profile?.image?.url,
  };

  return (
    <>
      <div className="relative flex w-full h-screen overflow-hidden">
        <div
          className={
            "fixed h-screen flex transition-all duration-200 z-10 " +
            (isMenuOpen ? "-translate-x-full lg:translate-x-0 w-60 lg:w-16" : "translate-x-0 w-60 lg:w-60")
          }
        >
          <DefaultSidebar isMenuOpen={isMenuOpen} settings={settings} />
        </div>
        <div
          className={
            "relative w-full flex flex-col transition-all duration-200 " +
            (isMenuOpen ? "p-0 lg:pl-16" : "p-0 lg:pl-60")
          }
        >
          <DefaultHeader
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            userData={loggedInUser}
            isToggleIcon={true}
            siteData={"AdminPannel"}
          />
          <div className="relative w-full h-full max-h-[calc(100vh-4rem)] px-6 pt-0 pb-6 overflow-auto scroll-smooth scrollbar bg-slate-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default InnerLayout;
