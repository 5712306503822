// import React, { Fragment, useState, useRef, useEffect } from 'react';
// import { Listbox, Transition } from '@headlessui/react';
// import { usePopper } from "react-popper";
// import { bottom } from "@popperjs/core";
// import { classNames } from "../../helpers/classNames";

// const Select = ({
//   xPlacement,
//   dropdownData,
//   dropdownClass, 
//   dropdownButtonClass, 
//   selectedValue, 
//   label,
//   labelClasses,
//   errorType,
//   errorText,
//   ...props
// }) => {
//   const [selected, setSelected] = useState(dropdownData[0]);

//   const popperElRef = useRef(null);
//   const [targetElement, setTargetElement] = useState(null);
//   const [popperElement, setPopperElement] = useState(null);

//   const placements = {
//     left: "bottom-start",
//     right: "bottom-end",
//     "": bottom,
//   };

//   const { styles, attributes } = usePopper(targetElement, popperElement, {
//     placement: placements[xPlacement],
//     modifiers: [
//       {
//         name: "offset",
//         options: {
//           offset: [0, 8],
//         }, 
//       },
//     ],
//   });

//   useEffect(() => {
//     if(selectedValue){
//       const index = dropdownData.findIndex((item) => item._id === selectedValue);
//       if(index !== -1){
//         setSelected(dropdownData[index]);
//       }
//     }
//   }, [selectedValue])

//   return (
//     <>
//       {label && 
//         <div className={classNames("text-sm font-medium text-slate-500 mb-1", labelClasses)}>{label}</div>
//       }
//       <Listbox value={selected} onChange={setSelected} as={'div'} className="relative">
//         <Listbox.Button ref={setTargetElement} className="flex items-center w-full">
//           <div className={classNames(
//             "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-slate-50 rounded-md border border-slate-200", 
//               dropdownButtonClass
//             )}>
//             <div className="text-[13px] text-slate-500 font-medium whitespace-nowrap truncate">{selected.name}</div>
//             <div className="text-xs text-slate-400">
//               <i className={"fa-light fa-fw fa-chevron-down"}></i>
//             </div>
//           </div>
//         </Listbox.Button>
//         <Transition
//           leave="transition duration-75" leaveFrom="opacity-100" leaveTo="opacity-0" className="w-full z-50"
//           beforeEnter={() => setPopperElement(popperElRef.current)}
//           afterLeave={() => setPopperElement(null)}
//           ref={popperElRef}
//           style={styles.popper}
//           {...attributes.popper}
//         >
//           <Listbox.Options className={classNames("w-60 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0 overflow-auto scroll-smooth scrollbar", dropdownClass)}>
//             <div className="py-1 max-h-80 divide-y divide-slate-100 ">
//             {dropdownData.map((item, index) => (
//               <Listbox.Option
//                 key={index}
//                 className={({ active }) => classNames("relative select-none py-2 pl-8 pr-2 text-xs leading-tight cursor-pointer transition-all duration-200", active?'bg-carnation-50 text-slate-700':'text-slate-500')}
//                 value={item}
//               >
//                 {({ selected }) => (
//                   <>
//                     <div className={classNames("block truncate", selected?'font-medium':'font-normal')}>{item.name}</div>
//                     {selected &&
//                       <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-carnation-500 text-base">
//                         <i className="fa-regular fa-fw fa-check"></i>
//                       </div>
//                     }
//                   </>
//                 )}
//               </Listbox.Option>
//             ))}
//             </div>
//           </Listbox.Options>
//         </Transition>
//       </Listbox>
//       {errorType && errorType === 'error' &&
//         <div className="text-xs text-red-600 mt-1">
//           <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>{errorText}
//         </div>
//       }
//       {errorType && errorType === 'warning' &&
//         <div className="text-xs text-amber-600 mt-1">
//           <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>{errorText}
//         </div>
//       }
//       {errorType && errorType === 'success' &&
//         <div className="text-xs text-green-600 mt-1">
//           <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>{errorText}
//         </div>
//       }
//       {errorType && errorType === 'info' &&
//         <div className="text-xs text-sky-600 mt-1">
//           <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>{errorText}
//         </div>
//       }
//     </>
//   );
// }

// export default Select;

import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import Search from "./Search";
import { classNames } from "../../helpers/classNames";

const Select = ({
  xPlacement,
  dropdownData = [],
  dropdownClass,
  dropdownButtonClass,
  selectedValue,
  label,
  labelClasses,
  errorType,
  errorText,
  isSearch,
  isEditList,
  setOptionsEditModal,
  setEditDataOption,
  buttonArrowIconClasses,
  buttonArrowIcon = "fa-regular fa-chevron-down",
  onChange = () => {},
  ...props
}) => {
  const [selected, setSelected] = useState(
    selectedValue ? selectedValue : dropdownData[0]
  );
  const [statusColor, setStatusColor] = useState();
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": "",
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  const status = useMemo(
    () => ({
      success:
        "!bg-emerald-100 !text-emerald-500 !text-xs !font-semibold py-1 px-2 rounded",
      danger:
        "!bg-rose-100 !text-rose-500 !text-xs !font-semibold py-1 px-2 rounded",
      warning:
        "!bg-amber-100 !text-amber-500 !text-xs !font-semibold py-1 px-2 rounded",
      info: "!bg-blue-100 !text-blue-500 !text-xs !font-semibold py-1 px-2 rounded",
      default:
        "!bg-org-grayBlue/20 !text-org-grayBlue !text-xs !font-semibold py-1 px-2 rounded",
      "": "",
    }),
    []
  );

  useEffect(() => {
    if (selectedValue) {
      const index = dropdownData.findIndex(
        (item) => item._id === selectedValue || item.value === selectedValue
      );
      if (index !== -1) {
        setSelected(selected?.name);
      }
      if (selected?.statusType) {
        setStatusColor(status[selected?.statusType]);
      }
    }
  }, [dropdownData, selected, selectedValue, status]);
  // console.log(selected, "jhjahjsjhjs");
  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-semibold text-org-gray tracking-none",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        <Listbox
          value={selected}
          onChange={(val) => {
            // console.log(val);
            onChange(val);
            setSelected({ ...val });
          }}
          as={"div"}
          className="relative"
        >
          {({ open }) => (
            <>
              <Listbox.Button
                ref={setTargetElement}
                className="flex items-center w-full"
              >
                <div
                  className={classNames(
                    "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-1 pl-3 pr-6 bg-white rounded-md border text-sm font-medium",
                    dropdownButtonClass,
                    open ? "border-org-graySky" : "border-org-border"
                  )}
                >
                  <div
                    className={classNames(
                      "text-org-gray whitespace-nowrap truncate",
                      statusColor ? statusColor : ""
                    )}
                  >
                    {selected?.name}
                  </div>
                  {buttonArrowIcon && (
                    <div
                      className={classNames(
                        "absolute top-1/2 right-1 -translate-y-1/2 transition-all duration-200 text-xs text-org-grayB",
                        open ? "-rotate-180" : "",
                        buttonArrowIconClasses
                      )}
                    >
                      <i className={classNames("fa-fw", buttonArrowIcon)}></i>
                    </div>
                  )}
                </div>
              </Listbox.Button>
              <Transition
                as={Fragment}
                className="z-50"
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
                beforeEnter={() => setPopperElement(popperElRef.current)}
                afterLeave={() => setPopperElement(null)}
                ref={popperElRef}
                style={styles.popper}
                {...attributes.popper}
              >
                <Listbox.Options
                  className={classNames(
                    "absolute mt-1 border border-org-border w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                    dropdownClass
                  )}
                >
                  {isSearch && (
                    <div className="py-2 px-3">
                      <Search placeholder={"Search"} />
                    </div>
                  )}
                  <div className="py-1 max-h-80 overflow-auto scrollbar">
                    {dropdownData?.map((item, index) => (
                      <Listbox.Option
                        key={index}
                        className={({ active }) =>
                          classNames(
                            "relative group flex w-full items-center px-3 py-1.5 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer",
                            active
                              ? "bg-org-veryLightBlue text-org-theme"
                              : "text-org-grayB",
                            item.isDisabled
                              ? "!bg-org-silver !cursor-not-allowed"
                              : "",
                            item.class
                          )
                        }
                        value={item}
                        disabled={item.isDisabled}
                      >
                        {({ selected }) => (
                          <>
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <div>{item.name}</div>
                            {selected && (
                              <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-org-theme text-base">
                                <i className="fa-regular fa-fw fa-check"></i>
                              </div>
                            )}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                    {isEditList && (
                      <Listbox.Option
                        onClick={() => {
                          setOptionsEditModal(true);
                          setEditDataOption(dropdownData);
                        }}
                        className="relative group flex w-full items-center px-3 py-2 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer hover:bg-org-veryLightBlue text-org-theme font-semibold border-t border-org-border"
                      >
                        Edit List
                      </Listbox.Option>
                    )}
                  </div>
                </Listbox.Options>
              </Transition>
            </>
          )}
        </Listbox>
        {errorType && errorType === "error" && (
          <div className="text-xs text-red-600 mt-1">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs text-amber-600 mt-1">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs text-green-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs text-sky-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default Select;
