import React from "react";
// import styles from "./styles/loder.module.css";
import styles from "./styles/loder.module.css";
const Loader = ({ loaderClasses }) => {
  return (
    <div className="flex flex-col items-center">
      <div className={`${styles.loader} ${loaderClasses}`}></div>
    </div>
  );
};

export default Loader;
