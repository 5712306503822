import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Select from "../../../../components/form/Select";
import Input from "../../../../components/form/Input";
import Button from "../../../../components/form/Button";
import Table from "../../../../components/elements/table/Table";
import {
  changeUserStatus,
  companyDetails,
  deleteUser,
  getAdminList,
  getCompanyList,
  videoOrdering,
} from "../../../../services/adminService/UserService";
import { useDebounce } from "../../../../helpers/useDebounce";
import { featured_filter, status_filter, trending_filter } from "../../../../constant/constant";
import DeleteModal from "../../../../components/common/DeleteModal";
import { useNavigate, useParams } from "react-router-dom";
import { superAdminDirectLogin } from "../../../../services/LoginService";
import Modal from "../../../../components/modal/Modal";
import Image from "../../../../components/elements/Image";
import SelectDropdownSearch from "../../../../components/form/SelectDropdownSearch";
import EditUser from "./EditUser";
import AddCompanyUser from "./AddCompanyUser";

const Details = () => {
  const { id } = useParams();
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState("-createdAt");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [keyword, setKeyword] = useState("");
  const search = useDebounce(keyword, 300);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [addEmployeeModal, setEmployeeModal] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedItem, setSelectedItem] = useState(list && list?.data && list?.data?.length > 0 && list?.data[0]?._id);
  const profile = useSelector((state) => state.profile.profileData);
  const [details, setDetails] = useState("");
  const [selectedRoleValue, setSelectedRoleValue] = useState({ name: "Employee", value: "employee" });
  const roleData = [
    { name: "Administrator", value: "admin" },
    { name: "Employee", value: "employee" },
  ];

  // loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));
    const statusData = {};
    if (status && Array.isArray(status) && status?.length > 0) {
      status?.forEach((item, i) => (statusData[`status[${i}]`] = item));
    }
    if (id) {
      getAdminList({
        sortQuery: sort,
        keyword: search,
        limit: 10000,
        page: currentPage,
        roleCode: selectedRoleValue?.value,
        ...statusData,
        companyId: id,
      }).then((res) => {
        if (res && res?.docs?.success) {
          setList({
            loading: false,
            data: res.docs?.data,
            pageCount: res?.docs?.metadata?.totalPages,
            keyword: keyword,
            totalItem: res?.docs?.metadata?.totalDocs,
          });
          setSelectedItem(res.docs?.data && res.docs?.data?.length > 0 && res.docs?.data[0]?._id);
          setCount(res?.docs?.metadata?.totalDocs);
          setPagination({
            hasNextPage: res?.docs?.metadata?.hasNextPage,
            hasPrevPage: res?.docs?.metadata?.hasPrevPage,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast.error(res?.message);
        }
      });
    }
  }, [limit, status, sort, featured, search, currentPage, count, id, selectedRoleValue?.value]);

  useEffect(() => {
    loadList();
  }, [loadList]);
  const handleItemClick = (id) => {
    setSelectedItem(id);
  };
  const getDetails = useCallback(() => {
    if (id) {
      setDetails({ loading: true, data: {} });
      companyDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails({ loading: false, data: res?.docs });
        } else {
          setDetails({ loading: false, data: {} });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const roleChange = (value) => {
    value ? setSelectedRoleValue(value) : setSelectedRoleValue(null);
  };
  return (
    <>
      <section className="flex flex-col lg:flex-row  justify-center items-center">
        <div className="flex flex-col justify-center items-start p-6 mr-8 lg:w-2/4">
          <h1 className="text-5xl font-bold text-gray-800 leading-snug mb-4">{details?.data?.name || "N/A"}</h1>
        </div>
      </section>
      <div className="flex justify-end">
        <Button
          buttonHasLink={false}
          buttonIcon={"fa-regular fa-plus"}
          buttonIconPosition={"left"}
          buttonClasses={
            "!bg-org-theme !border-org-theme !text-white hover:!bg-white !border-org-theme  hover:!text-org-theme"
          }
          buttonLabel={"Add New"}
          buttonLabelClasses={"font-medium !text-sm"}
          buttonFunction={() => {
            setEmployeeModal(true);
          }}
        />
      </div>
      <div className="block sm:flex  gap-2 space-y-4 sm:space-y-0">
        <div className="w-full sm:w-4/12 border rounded-t-md bg-org-silver ">
          <div className="bg-org-theme rounded-t-md h-10 flex justify-center items-center">
            <p className="text-white py-6">List Of Employees</p>
          </div>
          <div className="flex flex-col items-center justify-between relative w-full  gap-2 my-2">
            <div className="flex items-center space-x-2 w-full px-2 mt-0">
              <div className="flex-1">
                <Input
                  inputType={"text"}
                  isInputGroup={true}
                  inputGroupPosition={"left"}
                  inputGroupIcon={"fa-regular fa-search"}
                  inputPlaceholder={"Search"}
                  inputClasses={"!pl-3"}
                />
              </div>
              <div className="w-40">
                <Select
                  dropdownData={roleData}
                  placeholder={"Select Role"}
                  dropdownButtonClass={"!h-10 !rounded-md"}
                  selectedValue={selectedRoleValue}
                  onChange={roleChange}
                />
              </div>
            </div>
            <div className="relative w-full h-[600px] overflow-auto scroll-smooth  scrollbar ">
              {list &&
                list?.data &&
                list?.data?.length > 0 &&
                list?.data?.map((item, index) => (
                  <div
                    className={`relative w-full px-2 cursor-pointer`}
                    key={index}
                    onClick={() => handleItemClick(item._id)}
                  >
                    <div
                      className={`relative w-full border border-slate-200 px-2 py-3 rounded-md flex items-start gap-2 ${
                        selectedItem === item?._id ? "border-green-700 border bg-green-100" : "border-slate-200"
                      }`}
                    >
                      <div className="w-14 h-14 overflow-hidden rounded-full shrink-0">
                        <Image src={item?.image?.url || ""} fill={true} alt={item?.name || "N/A"} />
                      </div>
                      <div className="relative w-full shrink-1">
                        <div className="realtive flex items-center w-full ">
                          <div className="realtive text-base text-slate-900 font-semibold">{item?.name || "N/A"}</div>
                          <div
                            className={`text-xs font-medium leading-tight rounded py-1 px-2 ml-auto ${
                              item?.status === "active"
                                ? "bg-green-100 text-green-600 border border-green-600"
                                : "bg-red-100 text-red-600 border-red-600 border"
                            }`}
                          >
                            {item?.status === "active" ? "Active" : "In Active"}
                          </div>
                        </div>
                        <div className="realtive flex flex-col mt-1 text-slate-400">
                          <div className="text-xs  font-normal ">{item?.email || "N/A"}</div>
                          <div className="text-xs  font-normal ">{item?.designation || "N/A"}</div>
                          <div className="text-sm  font-bold text-org-theme">
                            {item?.roleCode === "employee"
                              ? "Employee"
                              : item?.roleCode === "admin"
                              ? "Administrator"
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="w-full sm:w-8/12 border rounded-t-md bg-org-silver">
          <div className="bg-org-theme rounded-t-md h-10 flex justify-center items-center">
            <p className="text-white py-6">
              {selectedRoleValue?.value === "admin" ? "Edit Administrator" : "Edit Employee"}
            </p>
          </div>
          <div className="relative">
            <EditUser
              id={selectedItem}
              setCount={setCount}
              setList={setList}
              companyId={details?.data?._id}
              selectedRoleValue={selectedRoleValue?.value}
            />
          </div>
        </div>
      </div>
      <Modal
        title={"Add New"}
        open={addEmployeeModal}
        size="xl4"
        onClose={() => {
          setEmployeeModal(false);
          setUserDetails({});
        }}
      >
        <AddCompanyUser
          setEmployeeModal={setEmployeeModal}
          addEmployeeModal={addEmployeeModal}
          companyId={id}
          setCount={setCount}
          setUserDetails={setUserDetails}
          userDetails={userDetails}
          count={count}
        />
      </Modal>
    </>
  );
};

export default Details;
