import React from "react";
import { classNames } from "../../helpers/classNames";
import Image from "./Image";

const Indentification = ({
  image,
  initial,
  className,
  divStyle,
  initialClass,
  initialStyle,
  defaultClass,
  defaultStyle,
  fill = true,
  alt,
  size = "lg",
  style,
}) => {
  const imageSize = {
    xs: "w-6 h-6",
    sm: "w-8 h-8",
    md: "w-10 h-10",
    lg: "w-12 h-12",
    xl: "w-16 h-16",
  };
  return (
    <>
      <div
        className={classNames(
          "overflow-hidden rounded-full flex items-center justify-center flex-shrink-0",
          imageSize[size],
          initial ? "text-sm" : "",
          className
        )}
        style={divStyle}
      >
        {image ? (
          <Image
            src={image}
            width={500}
            height={500}
            alt={alt}
            className={classNames(
              "w-full h-full",
              fill ? "object-cover" : "object-contain"
            )}
          />
        ) : initial ? (
          <div
            className={classNames(
              "w-full h-full flex items-center justify-center font-medium uppercase bg-org-theme text-org-veryLightBlue !leading-none",
              initialClass
            )}
            style={initialStyle}
          >
            {initial}
          </div>
        ) : (
          <div
            className={classNames(
              "w-full h-full flex items-center justify-center bg-org-veryLightBlue text-org-graySky",
              defaultClass
            )}
            style={defaultStyle}
          >
            <i className="fa-light fa-fw fa-user"></i>
          </div>
        )}
      </div>
    </>
  );
};

export default Indentification;
