import React, { useCallback, useEffect, useState } from "react";
import MainBannerItems from "./MainBannerItems";

const MainBanner = () => {
  return (
    <>
      <div className="mainbannerslide relative !h-full !text-black">
        <div className="relative !h-full">
          <MainBannerItems />
        </div>
      </div>
    </>
  );
};

export default MainBanner;
