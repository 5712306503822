import { apiRequest } from "../util/async/apiUtils";

export const loadProfileDetails = async (setDetails = () => {}, setLoaded = () => {}) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `profile/details`);

    const data = res.data;

    if (res?.data?.success) {
      setDetails(res?.data?.docs);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const sendEmail = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/send-otp"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    console.log(err, "csasas");
    return err;
  }
};

export const verifyOtp = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/verify-otp"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
export const resetPassword = async (params) => {
  try {
    const res = await apiRequest(
      `${"post"}`,
      `${"/reset-password"}`,
      {
        body: {
          ...params,
        },
      }
      // "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
export const getProfileDetails = async (setResData = () => {}, setLoaded = () => {}) => {
  try {
    const res = await apiRequest("get", "profile/details");

    const data = res.data;
    // console.log(res, "resss");
    if (res?.data?.success) setResData(res.data.docs);

    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
  }
};

export const updateProfile = async (params) => {
  try {
    const res = await apiRequest(
      `${params?.id ? "put" : "post"}`,
      `profile/edit`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
