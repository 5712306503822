import React, { useState } from "react";

// Custom hook for form validation
const useForm = (initialState = {}, validationRules) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const setFieldsValue = (data) => {
    setValues(data);
  };

  const handleChange = (e) => {
    let element = e.target.type;
    const { name, value, checked, files } = e.target;

    switch (element) {
      case "checkbox":
        setValues({
          ...values,
          [name]: checked,
        });
        break;
      case "file":
        const multiple = e.target.multiple;

        if (multiple) {
          setValues({
            ...values,
            [name]: files,
          });
        } else {
          setValues({
            ...values,
            [name]: files[0],
          });
        }
        break;
      default:
        setValues({
          ...values,
          [name]: value,
        });
        break;
    }

    // Validate the field
    const newErrors = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: newErrors[name], // Update error for this field
    }));
  };

  const validateField = (fieldName, value) => {
    const fieldErrors = {};
    const rule = validationRules[fieldName];
    if (rule && rule.required && !value) {
      fieldErrors[fieldName] = {
        message: rule.message || `${fieldName} is required`,
        type: "required",
      };
    }
    return fieldErrors;
  };

  const handleSubmit = (e, onSubmit = () => {}) => {
    e.preventDefault();
    let formErrors = validateForm(values);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      // Submit the form
      formErrors = {};
      setErrors({});
      onSubmit(values);
    }
  };

  const validateForm = (values) => {
    const formErrors = {};
    for (const key in validationRules) {
      if (validationRules.hasOwnProperty(key)) {
        const value = values[key];
        const fieldErrors = validateField(key, value);
        if (Object.keys(fieldErrors).length > 0) {
          formErrors[key] = fieldErrors[key];
        }
      }
    }
    return formErrors;
  };

  function resetField() {
    const fields = {};
    if (Object?.keys(initialState)?.length > 0) {
      Object.keys(initialState)?.forEach((key) => {
        fields[key] = "";
      });
      setValues(fields);
      setErrors({});
    } else {
      setValues({});
      setErrors({});
    }
  }

  return {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  };
};

export default useForm;
