import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../../components/form/Button";
import Input from "../../../../components/form/Input";
import SingleImageUpload from "../../../../components/form/SingleImageUpload";
import { formValidate } from "../../../../helpers/formValidate";
import { Validation } from "../../../../helpers/Validation";
import useForm from "../../../../hooks/useForm";
import DatePickerInput from "../../../../components/form/DatePickerInput";
import { toast } from "react-toastify";
import { addEmployee, employeeDetails, getReportList } from "../../../../services/adminService/UserService";
import SelectDropdownSearch from "../../../../components/form/SelectDropdownSearch";
import { getPositionList } from "../../../../services/adminService/PositionService";

const AddCompanyUser = ({
  setEmployeeModal = () => {},
  companyId,
  setCount = () => {},
  userDetails,
  setUserDetails = () => {},
}) => {
  const [selectedValue, setSelectedValue] = useState({});
  const validation = {
    name: { required: true, message: "Please enter name" },
    email: { required: true, message: "Please enter email" },
    password: {
      required: selectedValue?.value === "admin" ? true : false,
      message: "Please enter your  password",
    },
    reportTo: { required: true, message: "Please Select Report to" },
    role: { required: true, message: "Please enter role" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [key, setKey] = useState("");
  const [reportList, setReportList] = useState([]);
  const [positionKeyword, setPositionKeyword] = useState("");
  const [positionList, setPositionList] = useState([]);

  const roleData = [
    { name: "Select Role", value: "" },
    { name: "Administrator", value: "admin" },
    { name: "Employee", value: "employee" },
  ];

  // const getDetails = useCallback(() => {
  //   if (userDetails?._id) {
  //     setDetailsData({ loading: true, data: {} });
  //     employeeDetails({ id: userDetails?._id, roleCode: "admin" }).then((res) => {
  //       if (res?.status === 200) {
  //         setDetailsData({ loading: true, data: res?.docs });
  //         const companyData =
  //           res?.docs?.companyDetail && res?.docs?.companyDetail.length > 0 && res?.docs?.companyDetail[0];
  //         setFieldsValue({
  //           name: res?.docs?.name || "",
  //           email: res?.docs?.email || "",
  //           phone: res?.docs?.phone || "",
  //           designation: res?.docs?.designation || "",
  //           image: res?.docs?.image?.url,
  //           company: companyData?._id,
  //           employeeId: res?.docs?.employeeId,
  //         });
  //         setImageUrl(res?.docs?.image?.url);
  //         setDate({
  //           startDate: res?.docs?.dateOfBirth,
  //           endDate: res?.docs?.dateOfBirth,
  //         });
  //       }
  //     });
  //   }
  // }, [userDetails?._id]);

  // useEffect(() => {
  //   getDetails();
  // }, [getDetails]);
  const loadReportList = useCallback(() => {
    if (selectedValue === "admin") {
      getReportList({
        keyword: key,
        limit: 1000,
        companyId: companyId,
        roleCode: "admin",
      }).then((res) => {
        if (res && res.docs?.success) {
          setReportList(res.docs.data);
          setCount(res.docs.metadata?.totalDocs);
        }
      });
    } else if (selectedValue === "employee") {
      getReportList({
        keyword: key,
        limit: 1000,
        companyId: companyId,
        roleCode: "employee",
      }).then((res) => {
        if (res && res.docs?.success) {
          setReportList(res.docs.data);
          setCount(res.docs.metadata?.totalDocs);
        }
      });
    }
  }, [key, selectedValue, companyId]);

  useEffect(() => {
    loadReportList();
  }, [loadReportList]);

  const loadPositionList = useCallback(() => {
    getPositionList({
      keyword: positionKeyword,
      limit: 99999,
      status: "active",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setPositionList(res?.docs?.data);
      } else {
        toast.error(res?.message);
      }
    });
  }, [positionKeyword]);

  useEffect(() => {
    loadPositionList();
  }, [loadPositionList]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.name)) {
        toast.error("Company name cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoaded(false);
        return;
      }
      if (selectedValue?.value === "admin" && !Validation("password", values.password)) {
        toast.error(
          "Password must be at least 8 characters long, contain at least one letter, one number, and one special character."
        );
        setLoaded(false);
        return;
      }
      if (selectedValue?.value === "admin" && !Validation("confirmPassword", values.confirmPassword, values.password)) {
        toast.error("Confirm password and password doesn`t match");
        setLoaded(false);
        return;
      }
      const res = await addEmployee({
        id: userDetails?._id,
        employeeId: values?.employeeId,
        image: imageUrl,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        designation: "title",
        positionId: values?.positionId,
        dateOfBirth: date?.startDate,
        roleCode: values?.role,
        companyId: companyId,
        reportTo: values?.reportTo,
        password: values?.password,
        confirmPassword: values.confirmPassword,
      });
      if (res?.data?.success) {
        toast.info(res?.data?.message);
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          setCount((pre) => pre + 1);
          setEmployeeModal(false);
        }
      } else {
        toast.error(res?.data?.error || res?.data?.message);
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      //   console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-id-card"}
                        inputGroupPosition={"left"}
                        label={"Employee Id"}
                        inputPlaceholder={"Enter Employee Id"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.employeeId}
                        inputName={"employeeId"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        inputPlaceholder={"Enter Employee Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        inputPlaceholder={"Enter Employee Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>

                    {/* <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-sharp fa-solid fa-clipboard-user"}
                        inputGroupPosition={"left"}
                        label={"Title"}
                        inputPlaceholder={"Enter Employee Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.designation}
                        inputName={"designation"}
                        onChange={handleChange}
                        {...formValidate(errors, "designation")}
                      />
                    </div> */}
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Title"}
                        placeholder={"Select a Title"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"positionId"}
                        isSearch={true}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        onSearch={(key) => setPositionKeyword(key)}
                        value={values?.positionId}
                        dropdownData={positionList?.map((item) => ({
                          name: item?.title,
                          _id: item?._id,
                        }))}
                        {...formValidate(errors, "positionId")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Role"}
                        placeholder={"Select a role"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"role"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={(value) => {
                          handleChange(value);
                          setSelectedValue(value?.target?.value);
                        }}
                        value={values?.role}
                        dropdownData={roleData?.map((item) => ({
                          name: item?.name,
                          value: item?.value,
                        }))}
                        {...formValidate(errors, "role")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Report To"}
                        placeholder={"Select Employee"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"reportTo"}
                        isSearch={true}
                        onSearch={(key) => setKey(key)}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        value={values?.reportTo}
                        isDisable={true}
                        dropdownData={reportList?.map((item) => ({
                          name: item?.name,
                          _id: item?._id,
                        }))}
                        // dropdownData={list}
                        {...formValidate(errors, "reportTo")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-phone"}
                        inputGroupPosition={"left"}
                        label={"Phone No"}
                        inputPlaceholder={"Enter Employee Phone No"}
                        labelClasses={"!text-base"}
                        inputType={"number"}
                        value={values.phone}
                        inputName={"phone"}
                        onChange={handleChange}
                        {...formValidate(errors, "phone")}
                      />
                    </div>

                    <div className="relative col-span-6">
                      <DatePickerInput
                        label="Date Of Birth"
                        asSingle={true}
                        useRange={false}
                        value={date}
                        onChange={(val) => setDate(val)}
                        showShortcuts={false}
                      />
                    </div>

                    {selectedValue === "admin" && (
                      <>
                        <div className="relative col-span-6">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"Password"}
                            inputPlaceholder={"Enter  Password"}
                            labelClasses={"!text-base"}
                            inputType={"password"}
                            value={values.password}
                            inputName={"password"}
                            onChange={handleChange}
                            {...formValidate(errors, "password")}
                          />
                        </div>
                        <div className="relative col-span-6">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"Confirm Password"}
                            inputPlaceholder={"Enter Confirm Password"}
                            labelClasses={"!text-base"}
                            inputType={"confirmPassword"}
                            value={values.confirmPassword}
                            inputName={"confirmPassword"}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4">
                <div className="relative">
                  <SingleImageUpload
                    label={"Image"}
                    size={"md"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-org-theme"}
                  buttonFunction={() => {
                    setEmployeeModal(false);
                    setUserDetails({});
                  }}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  isDisable={loaded ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddCompanyUser;
