import React from "react";
import Datepicker from "react-tailwindcss-datepicker";

const DatePickerInput = ({
  label,
  labelClasses,
  value,
  onChange,
  useRange = true,
  asSingle = false,
  showShortcuts = false,
}) => {
  const handleDateChange = (newDate) => {
    onChange(newDate);
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayDate = yesterday.toISOString().split("T")[0];

  return (
    <div className="relative space-y-1 w-full">
      {label && (
        <div className={`${labelClasses} text-sm font-medium text-slate-600 px-3  tracking-none`}>
          <span>{label}</span>
        </div>
      )}
      <Datepicker
        primaryColor={"green"}
        value={value}
        asSingle={asSingle}
        useRange={useRange}
        displayFormat="MM-DD-YYYY"
        onChange={handleDateChange}
        showShortcuts={showShortcuts}
        popoverDirection="down"
        containerClassName={"relative"}
        maxDate={yesterdayDate}
      />
    </div>
  );
};

export default DatePickerInput;
