import React from "react";
import { classNames } from "../../helpers/classNames";

const Checkbox = ({
  checkboxName,
  checkboxInputClass,
  isChecked,
  checked,
  checkboxClass,
  checkboxLabel,
  checkboxLableClass,
  onChange = () => {},
  value,
  strike = false,
  ...props
}) => {
  return (
    <>
      <div className="relative overflow-hidden flex items-center flex-shrink-0">
        <input
          type="checkbox"
          name={checkboxName}
          className={classNames("peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer", checkboxInputClass)}
          checked={isChecked}
          onChange={(e) => onChange(e.target.checked, value)}
        />
        <div
          className={classNames(
            "w-5 h-5 flex-shrink-0 border border-slate-300 rounded flex items-center justify-center text-sm bg-white text-black/0 peer-checked:bg-org-theme peer-checked:border-org-theme peer-checked:text-white",
            checkboxClass
          )}
        >
          <i className="fa-light fa-check"></i>
        </div>
        {checkboxLabel && (
          <div
            className={classNames(
              "text-base font-medium text-slate-600 w-full ml-3",
              strike ? "peer-checked:line-through" : "",
              checkboxLableClass
            )}
          >
            {checkboxLabel}
          </div>
        )}
      </div>
    </>
  );
};

export default Checkbox;
