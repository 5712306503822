import React from "react";
import Tbody from "./Tbody";
import Thead from "./Thead";
import NoDataFound from "../../common/NoDataFound";
import { classNames } from "../../../helpers/classNames";
import Loader from "../../common/Loader";
import Pagination from "../../common/Pagination";
import { handleOnDragEnd } from "../../../helpers";
import { DragDropContext } from "react-beautiful-dnd";

const Table = ({
  tableData = [],
  tableHeadData = [],
  containerClasses,
  tableClasses,
  isLoder,
  onPageChange = () => {},
  pageCount,
  currentPage,
  list,
  setSelectedItems = () => {},
  selectedItems = [],
  isOrderingDisabled = true,
  handleOrdering = () => {},
  setOriginalData = () => {},
  isCheckbox = false,
  originalData = [],
}) => {
  const whiteStripe =
    "after:content-[''] after:absolute after:right-0 after:top-0 after:w-6 after:h-full after:z-[1] after:bg-white";

  const colspan = tableHeadData.length;
  const onDragEnd = (result) => {
    handleOnDragEnd(result, originalData, setOriginalData, handleOrdering);
  };
  const selectAllCheckBox = () => {
    if (selectedItems.length === tableData.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(tableData.map((item, index) => item._id));
    }
  };

  const heandleCheckboxSelection = (item) => {
    setSelectedItems((items) =>
      items.includes(item._id)
        ? items.filter((filterItem) => filterItem !== item._id)
        : [...items, item._id]
    );
  };

  if (isCheckbox) {
    if (!(tableHeadData[0].type === "checkbox")) {
      tableHeadData.unshift({
        _id: crypto.randomUUID(),
        type: "checkbox",
        name: "",
        align: "left",
        isSort: false,
        isFilter: false,
        thClasses: "w-[1%] !bg-black/5",
        checked: selectedItems.length
          ? selectedItems.length === tableData.length
          : false,
        onCheckboxChange: selectAllCheckBox,
      });
    }
    tableData?.forEach((element) => {
      const rowData = element.rowData;
      if (rowData[0].type !== "checkbox") {
        let obj = {
          _id: crypto.randomUUID(),
          type: "checkbox",
          align: "left",
          data: "X",
          tdClasses: "w-[1%]",
          itemDetails: element,
          checked: selectedItems.includes(element._id),
          onCheckboxChange: heandleCheckboxSelection,
        };
        rowData.unshift(obj);
      }
    });
  }
  return (
    <>
      <div
        className={classNames(
          "relative w-full overflow-auto scroll-smooth  scrollbar  ",
          containerClasses
        )}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <table
            className={classNames(
              "min-w-max w-full table-auto rounded-md  shadow bg-white",
              tableClasses
            )}
          >
            <Thead tableHeadData={tableHeadData} />
            <Tbody
              tableData={tableData}
              list={list}
              isOrdering={isOrderingDisabled}
            />
          </table>
        </DragDropContext>
        {isLoder && (
          <div className="w-full flex items-center justify-center">
            <Loader />
          </div>
        )}

        {!isLoder && tableData?.length === 0 && <NoDataFound />}

        {pageCount > 1 && (
          <Pagination
            onPageChange={onPageChange}
            paginationClasses={"justify-end mt-5"}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        )}
      </div>
    </>
  );
};

export default Table;
