// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { apiRequest } from "../util/async/apiUtils";

// const ProtectedRoute = (props) => {
//   const dispatch = useDispatch();
//   let navigate = useNavigate();
//   const [auth, setAuth] = useState(false);
//   const [isLoader, setIsLoader] = useState(true);
//   let token_ = localStorage.getItem("accessToken");
//   const role = localStorage.getItem("role");
//   const accessRole = [
//     "superadmin",
//     ...(Array.isArray(props.getAccess) ? props.getAccess : []),
//   ];

//   useEffect(() => {
//     const checkData = async () => {
//       try {
//         const res = await apiRequest("post", "verify-token", {});
//         if (res?.status === 401) {
//           localStorage.removeItem("auth_token");
//           navigate("/signin");
//         } else {
//           setAuth(true);
//         }
//       } catch (error) {
//         console.error("Error verifying token:", error);
//         // Optionally handle different error statuses
//         if (error.response?.status === 401) {
//           localStorage.removeItem("auth_token");
//           navigate("/signin");
//         } else {
//           // Handle other types of errors (e.g., network issues)
//           console.error("Unexpected error:", error);
//           localStorage.removeItem("auth_token");
//           navigate("/signin");
//         }
//       }
//     };

//     if (!token_) {
//       setIsLoader(false);
//       navigate("/signin");
//     } else {
//       checkData();
//     }
//   }, [token_, dispatch]);

//   // useEffect(() => {
//   //   async function checkPath() {
//   //     let checkRole = accessRole.filter((item) => item === role);
//   //     // console.log(checkRole.length, "checkRole");
//   //     if (role === "superadmin") {
//   //       return navigate("/admin/adminList");
//   //     } else if (role === "admin") {
//   //       return navigate("/");
//   //     } else {
//   //       return navigate("/signin");
//   //     }
//   //   }
//   //   checkPath();
//   // }, [dispatch, window.location.pathname, props.getAccess]);

//   console.log(
//     props.getAccess,
//     "window.location.pathnamewindow.location.pathname"
//   );

//   return <>{auth && props.children}</>;
// };

// export default ProtectedRoute;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../util/async/apiUtils";
import { authLogin, authLogout, authSettingsSetup } from "../store/authSlice";

export const ProtectedRoute = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");
  const accessRole = [
    ...(Array.isArray(props.getAccess) ? props.getAccess : []),
  ];
  useEffect(() => {
    const checkData = async () => {
      try {
        const res = await apiRequest("post", "verify-token", {});
        if (res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/signin");
        } else {
          setAuth(true);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        // Optionally handle different error statuses
        if (error?.response?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/signin");
        } else {
          // Handle other types of errors (e.g., network issues)
          console.error("Unexpected error:", error);
          localStorage.removeItem("auth_token");
          navigate("/signin");
        }
      }
    };

    if (!token_) {
      setIsLoader(false);
      navigate("/signin");
    } else {
      checkData();
    }
  }, [token_, dispatch]);

  useEffect(() => {
    async function checkPath() {
      let checkRole = accessRole.filter((item) => item === role);
      // console.log(checkRole, "checkRole");
      if (checkRole && checkRole.length === 0) {
        if (role === "superadmin") {
          return navigate("/admin/dashboard");
        } else if (role === "admin") {
          return navigate("/");
        } else {
          return navigate("/signin");
        }
      }
    }
    checkPath();
  }, [dispatch, window.location.pathname, props.getAccess]);

  return <>{auth && props.children}</>;
};
