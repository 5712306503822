export const status_filter = [
    { value: "active", name: "Active", type: "checkbox" },
    { value: "inActive", name: "In-active", type: "checkbox" },
  ];
  export const featured_filter = [
    { value: 1, name: "Featured", type: "checkbox" },
    { value: 0, name: "Non-featured", type: "checkbox" },
  ];
  export const trending_filter = [
    { value: 1, name: "Trending", type: "checkbox" },
    { value: 0, name: "Non-trending", type: "checkbox" },
  ];
  export const approved_filter = [
    { value: true, name: "Approved", type: "checkbox" },
    { value: false, name: "Non-approved", type: "checkbox" },
  ];
  export const member_filter = [
    { value: true, name: "Member", type: "checkbox" },
    { value: false, name: "Not-member", type: "checkbox" },
  ];
  export const email_filter = [
    { value: true, name: "Verified", type: "checkbox" },
    { value: false, name: "Not-verified", type: "checkbox" },
  ];
  export const attr_filter = [
    { value: true, name: "Value", type: "checkbox" },
    { value: false, name: "Attribute", type: "checkbox" },
  ];
  export const order_status_filter = [
    { value: "processing", name: "Processing", type: "checkbox" },
    { value: "shipped", name: "Shipped", type: "checkbox" },
    { value: "delivered", name: "Delivered", type: "checkbox" },
    { value: "cancelled", name: "Cancelled", type: "checkbox" },
  ];
  export const payment_status_filter = [
    { value: "pending", name: "Pending", type: "checkbox" },
    { value: "success", name: "Success", type: "checkbox" },
    { value: "failed", name: "Failed", type: "checkbox" },
  ];
  export const payment_method_filter = [
    { value: "online", name: "Online", type: "checkbox" },
    { value: "cash", name: "Cash", type: "checkbox" },
  ];
  