import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Select from "../../../components/form/Select";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import { Validation } from "../../../helpers/Validation";
import useForm from "../../../hooks/useForm";
import DatePickerInput from "../../../components/form/DatePickerInput";
import { toast } from "react-toastify";
import { getProfileDetails, updateProfile } from "../../../services/ProfileService";
import { addCompany, companyDetails } from "../../../services/adminService/UserService";
import { useNavigate, useParams } from "react-router-dom";

const AddEditComapny = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const validation = {
    companyName: { required: true, message: "Please enter your company name" },
    // companyEmail: {
    //   required: true,
    //   message: "Please enter your company email",
    // },
    name: {
      required: id ? false : true,
      message: "Please enter administrator name",
    },
    email: {
      required: id ? false : true,
      message: "Please enter your administrator email",
    },
    designation: {
      required: id ? false : true,
      message: "Please enter your administrator title",
    },

    password: {
      required: id ? false : true,
      message: "Please enter your administrator password",
    },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");
  const [resData, setResData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      companyDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          const adminData =
            res?.docs?.companyAdminDetail &&
            res?.docs?.companyAdminDetail.length > 0 &&
            res?.docs?.companyAdminDetail[0];
          setFieldsValue({
            companyName: res?.docs?.name || "",
            companyEmail: res?.docs?.email || "",
            name: adminData?.name || "",
            email: adminData?.email || "",
            phone: adminData.phone || "",
            designation: adminData.designation || "",
            image: res?.docs?.image?.url,
          });
          setImageUrl(res?.docs?.image?.url);
        }
      });
    }
  }, [id]);
  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const handleDateChange = (value) => {
    setDate(value?.startDate);
  };

  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?).");
    }

    return errors;
  };

  const onSubmit = async (values) => {
    const adminData =
      detailsData?.data?.companyAdminDetail &&
      detailsData?.data?.companyAdminDetail.length > 0 &&
      detailsData?.data?.companyAdminDetail[0];
    if (id) {
      setLoaded(true);
      try {
        if (!Validation("name", values.name)) {
          toast.error("Company name cannot be empty or contain only spaces.");
          setLoaded(false);
          return;
        }
        if (!Validation("email", values.email)) {
          toast.error("Invalid email format. Please enter a valid email.");
          setLoaded(false);
          return;
        }

        const res = await addCompany({
          companyId: id,
          companyName: values?.companyName,
          companyEmail: values?.email,
          image: imageUrl,
          // userId: adminData?._id,
          // userName: values?.name,
          // userEmail: values?.email,
          // phone: values?.phone,
          // designation: values?.designation,
          // password: values?.password,
          // confirmPassword: values?.password,
        });
        if (res?.data?.success) {
          toast.info(res?.data?.message);
          if (res?.data?.status === 200 || res?.data?.status === 201) {
            navigate("/admin/companies");
          }
        } else {
          toast.error(res?.data?.error);
        }
        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    } else {
      setLoaded(true);
      try {
        if (!Validation("name", values.name)) {
          toast.error("Company name cannot be empty or contain only spaces.");
          return;
        }
        if (!Validation("email", values.email)) {
          toast.error("Invalid email format. Please enter a valid email.");
          return;
        }
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          return;
        }
        if (!Validation("confirmPassword", values.confirmPassword, values.password)) {
          toast.error("Confirm password and password doesn`t match");
          return;
        }
        const res = await addCompany({
          employeeId: values?.employeeId,
          companyName: values?.companyName,
          companyEmail: values?.email,
          image: imageUrl,
          userName: values?.name,
          userEmail: values?.email,
          // phone: values?.phone,
          // designation: values?.designation,
          password: values?.password,
          confirmPassword: values?.confirmPassword,
        });
        if (res?.data?.success) {
          toast.info(res?.data?.message);
          if (res?.data?.status === 200 || res?.data?.status === 201) {
            navigate("/admin/companies");
          }
        } else {
          toast.error(res?.data?.error);
        }
        setLoaded(false);
      } catch (err) {
        setLoaded(false);
        console.error(err);
        toast.error("Somthing went wrong !!!");
      }
    }
  };
  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={id ? `Edit Company` : "Add Company"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/companies"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-org-theme"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-org-theme !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded ? true : false}
              />
            </div>
          )}
        />
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-12 text-slate-800 font-bold text-md">Company Info</div>
                    <div className="relative col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Company Name"}
                        inputPlaceholder={"Enter Company Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.companyName}
                        inputName={"companyName"}
                        onChange={handleChange}
                        {...formValidate(errors, "companyName")}
                      />
                    </div>
                    <div className="relative col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Company Email"}
                        inputPlaceholder={"Enter Company Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    {!id && (
                      <>
                        <>
                          <div className="relative col-span-12 text-slate-800 font-bold text-md">
                            Administrator Info
                          </div>
                          <div className="relative col-span-6">
                            <Input
                              isInputGroup={true}
                              inputGroupIcon={"fa-solid fa-id-card"}
                              inputGroupPosition={"left"}
                              label={"Administrator Id"}
                              inputPlaceholder={"Enter Administrator Id"}
                              labelClasses={"!text-base"}
                              inputType={"text"}
                              value={values.employeeId}
                              inputName={"employeeId"}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="relative col-span-6">
                            <Input
                              isInputGroup={true}
                              inputGroupIcon={"fa-regular fa-user"}
                              inputGroupPosition={"left"}
                              label={"Name"}
                              inputPlaceholder={"Enter Administrator Name"}
                              labelClasses={"!text-base"}
                              inputType={"text"}
                              value={values.name}
                              inputName={"name"}
                              onChange={handleChange}
                              {...formValidate(errors, "name")}
                            />
                          </div>
                          <div className="relative col-span-12">
                            <Input
                              isInputGroup={true}
                              inputGroupIcon={"fa-solid fa-envelope"}
                              inputGroupPosition={"left"}
                              label={"Email"}
                              inputPlaceholder={"Enter Administrator Email"}
                              labelClasses={"!text-base"}
                              inputType={"text"}
                              value={values.email}
                              inputName={"email"}
                              onChange={handleChange}
                            />
                          </div>
                          <>
                            {/* <div className="relative col-span-6">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"Password"}
                            inputPlaceholder={"Enter  Password"}
                            labelClasses={"!text-base"}
                            inputType={"password"}
                            value={values.password}
                            inputName={"password"}
                            onChange={handleChange}
                            {...formValidate(errors, "password")}
                          />
                        </div> */}
                          </>
                          {/* <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Confirm Password"}
                        inputPlaceholder={"Enter Confirm Password"}
                        labelClasses={"!text-base"}
                        inputType={"confirmPassword"}
                        value={values.confirmPassword}
                        inputName={"confirmPassword"}
                        onChange={handleChange}
                        {...formValidate(errors, "confirmPassword")}
                      />
                    </div> */}

                          {/* <div className="relative col-span-6">
                            <Input
                              isInputGroup={true}
                              inputGroupIcon={"fa-solid fa-phone"}
                              inputGroupPosition={"left"}
                              label={"Phone No"}
                              inputPlaceholder={"Enter Administrator Phone No"}
                              labelClasses={"!text-base"}
                              inputType={"text"}
                              value={values.phone}
                              inputName={"phone"}
                              onChange={handleChange}
                              {...formValidate(errors, "phone")}
                            />
                          </div> */}
                          <div className="relative col-span-6">
                            <Input
                              isInputGroup={true}
                              inputGroupIcon={"fa-solid fa-key"}
                              inputGroupPosition={"left"}
                              label={"Password"}
                              inputPlaceholder={"Enter  Password"}
                              labelClasses={"!text-base"}
                              inputType={"password"}
                              value={values.password}
                              inputName={"password"}
                              onChange={handleChange}
                              {...formValidate(errors, "password")}
                            />
                          </div>
                          <div className="relative col-span-6">
                            <Input
                              isInputGroup={true}
                              inputGroupIcon={"fa-solid fa-key"}
                              inputGroupPosition={"left"}
                              label={"Confirm Password"}
                              inputPlaceholder={"Enter Confirm Password"}
                              labelClasses={"!text-base"}
                              inputType={"confirmPassword"}
                              value={values.confirmPassword}
                              inputName={"confirmPassword"}
                              onChange={handleChange}
                            />
                          </div>
                        </>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4">
                <div className="relative">
                  <SingleImageUpload
                    label={"Company Image"}
                    size={"lg"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/companies"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-org-theme"}
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  isDisable={loaded ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditComapny;
