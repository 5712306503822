import { apiRequest } from "../../util/async/apiUtils";

export const getPositionList = async (params) => {
  try {
    const res = await apiRequest("get", "/position/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changePositionStatusSingle = async (payload = {}) => {
  try {
    const res = await apiRequest("put", `position/status/${payload?.ids}`, {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};
export const changePositionStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("put", `position/status`, {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const addEditPosition = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "put" : "post",
      params.id ? "position/edit" : "position/add",
      {
        body: params,
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deletePosition = async (params) => {
  try {
    const res = await apiRequest("delete", `position/delete/${params?.id}`, {
      body: {},
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};



