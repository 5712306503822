import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Validation } from "../../../../helpers/Validation";
import { formValidate } from "../../../../helpers/formValidate";
import Input from "../../../../components/form/Input";
import useForm from "../../../../hooks/useForm";
import {
  addEmployee,
  employeeDetails,
  getCompanyList,
  getReportList,
} from "../../../../services/adminService/UserService";
import DatePickerInput from "../../../../components/form/DatePickerInput";
import SingleImageUpload from "../../../../components/form/SingleImageUpload";
import Button from "../../../../components/form/Button";

import SelectDropdownSearch from "../../../../components/form/SelectDropdownSearch";
import { getPositionList } from "../../../../services/adminService/PositionService";

const EditUser = ({ id, setList, companyId }) => {
  //   const { id } = useParams();

  const [selectedValue, setSelectedValue] = useState();
  // console.log(selectedValue, "select");
  const validation = {
    name: { required: true, message: "Please enter employee name" },
    email: { required: true, message: "Please enter employee email" },

    designation: {
      required: true,
      message: "Please enter employee title",
    },
    reportTo: { required: true, message: "Please Select Report to" },
    // password: {
    //   required: id && selectedValue?.value === "admin" ? false : true,
    //   message: "Please enter your  password",
    // },
    role: { required: true, message: "Please enter role" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } = useForm({}, validation);
  const [imageUrl, setImageUrl] = useState("");

  const [loaded, setLoaded] = useState(false);
  const [date, setDate] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  // console.log(detailsData, "details");
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [keyword, setKeyword] = useState("");
  const [lists, setLists] = useState([]);
  const [key, setKey] = useState("");
  const [reportList, setReportList] = useState([]);
  const [positionKeyword, setPositionKeyword] = useState("");
  const [positionList, setPositionList] = useState([]);

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      employeeDetails({ id }).then((res) => {
        // console.log(res, "res");
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            name: res?.docs?.name || "",
            email: res?.docs?.email || "",
            phone: res?.docs?.phone || "",
            designation: res?.docs?.designation || "",
            image: res?.docs?.image?.url,
            employeeId: res?.docs?.employeeId,
            role: res?.docs?.roleCode,
            reportTo: res?.docs?.reportToField?._id ? res?.docs?.reportToField?._id : null,
            positionId: res?.docs?.positionDetail?._id ? res?.docs?.positionDetail?._id : null,
          });
          setImageUrl(res?.docs?.image?.url);
          setDate({
            startDate: res?.docs?.dateOfBirth,
            endDate: res?.docs?.dateOfBirth,
          });
          setSelectedValue(res?.docs?.roleCode);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);
  const loadReportList = useCallback(() => {
    if (selectedValue === "admin" || detailsData?.data?.roleCode === "admin") {
      getReportList({
        keyword: key,
        limit: 100000,
        companyId: companyId,
        roleCode: "admin",
      }).then((res) => {
        if (res && res.docs?.success) {
          setReportList(res.docs.data);
        }
      });
    } else if (selectedValue === "employee" || detailsData?.data?.roleCode === "employee") {
      getReportList({
        keyword: key,
        limit: 100000,
        companyId: companyId,
        roleCode: "employee",
      }).then((res) => {
        if (res && res.docs?.success) {
          setReportList(res.docs.data);
        }
      });
    }
  }, [key, selectedValue, companyId, detailsData]);

  useEffect(() => {
    loadReportList();
  }, [loadReportList]);
  // loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    getCompanyList({
      keyword: keyword,
      limit: limit,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setLists(res?.docs?.data);
        // setCount(res?.docs?.metadata?.totalDocs);
      } else {
        toast.error(res?.message);
      }
    });
  }, [limit, keyword]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const loadPositionList = useCallback(() => {
    getPositionList({
      keyword: positionKeyword,
      limit: 99999,
      status: "active",
    }).then((res) => {
      if (res && res?.docs?.success) {
        setPositionList(res?.docs?.data);
      } else {
        toast.error(res?.message);
      }
    });
  }, [positionKeyword]);

  useEffect(() => {
    loadPositionList();
  }, [loadPositionList]);

  const roleData = [
    { name: "Administrator", value: "admin" },
    { name: "Employee", value: "employee" },
  ];
  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push("Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?).");
    }

    return errors;
  };

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.name)) {
        toast.error("Company name cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoaded(false);
        return;
      }
      // if (!id && selectedValue?.value === "admin" && !Validation("password", values.password)) {
      //   toast.error(
      //     "Password must be at least 8 characters long, contain at least one letter, one number, and one special character."
      //   );
      //   return;
      // }
      if (!id && selectedValue === "admin") {
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }
      if (!id && !Validation("confirmPassword", values.confirmPassword, values.password)) {
        toast.error("Confirm password and password doesn`t match");
        setLoaded(false);
        return;
      }
      const res = await addEmployee({
        id: id,
        image: imageUrl,
        name: values?.name,
        email: values?.email,
        phone: values?.phone,
        designation: values?.designation,
        dateOfBirth: date?.startDate,
        companyId: companyId,
        employeeId: values?.employeeId,
        positionId: values?.positionId,
        roleCode: selectedValue,
        reportTo: values?.reportTo,
        password: values?.password,
        confirmPassword: values.confirmPassword,
      });
      if (res?.data?.success) {
        toast.info(res?.data?.message);
        if (res?.data?.status === 200 || res?.data?.status === 201) {
          setList((prevList) => ({
            ...prevList,
            data: prevList.data.map((item) =>
              item._id === id
                ? {
                    ...item,
                    name: res?.data?.data?.name,
                    image: res?.data?.data?.image?.url,
                    designation: res?.data?.data?.designation,
                    email: res?.data?.data?.email,
                    roleCode: res?.data?.data?.roleCode,
                  }
                : item
            ),
          }));
        }
      } else {
        toast.error(res?.data?.error);
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      //   console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-id-card"}
                        inputGroupPosition={"left"}
                        label={"Employee Id"}
                        inputPlaceholder={"Enter Employee Id"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.employeeId}
                        inputName={"employeeId"}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        inputPlaceholder={"Enter Employee Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative col-span-12">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        inputPlaceholder={"Enter Employee Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative col-span-12">
                      {/* <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-sharp fa-solid fa-clipboard-user"}
                        inputGroupPosition={"left"}
                        label={"Title"}
                        inputPlaceholder={"Enter Employee Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.designation}
                        inputName={"designation"}
                        onChange={handleChange}
                        {...formValidate(errors, "designation")}
                      /> */}
                      <div className="relative col-span-6">
                        <SelectDropdownSearch
                          label={"Title"}
                          placeholder={"Select a Title"}
                          xPlacement={"bottom"}
                          buttonArrowIcon={""}
                          selectName={"positionId"}
                          isSearch={true}
                          dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                          onChange={handleChange}
                          onSearch={(key) => setPositionKeyword(key)}
                          value={values?.positionId}
                          dropdownData={positionList?.map((item) => ({
                            name: item?.title,
                            _id: item?._id,
                          }))}
                          {...formValidate(errors, "positionId")}
                        />
                      </div>
                    </div>

                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Role"}
                        placeholder={"Select a role"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"role"}
                        isSearch={false}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={(value) => {
                          handleChange(value);
                          setSelectedValue(value?.target?.value);
                        }}
                        value={values?.role}
                        dropdownData={roleData?.map((item) => ({
                          name: item?.name,
                          value: item?.value,
                        }))}
                        {...formValidate(errors, "role")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <SelectDropdownSearch
                        label={"Report To"}
                        placeholder={"Select Employee"}
                        xPlacement={"bottom"}
                        buttonArrowIcon={""}
                        selectName={"reportTo"}
                        isSearch={true}
                        onSearch={(key) => setKey(key)}
                        dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                        onChange={handleChange}
                        value={values?.reportTo}
                        dropdownData={reportList?.map((item) => ({
                          name: item?.name,
                          _id: item?._id,
                        }))}
                        // dropdownData={list}
                        {...formValidate(errors, "reportTo")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-phone"}
                        inputGroupPosition={"left"}
                        label={"Phone No"}
                        inputPlaceholder={"Enter Employee Phone No"}
                        labelClasses={"!text-base"}
                        inputType={"number"}
                        value={values.phone}
                        inputName={"phone"}
                        onChange={handleChange}
                        {...formValidate(errors, "phone")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <DatePickerInput
                        label="Date Of Birth"
                        asSingle={true}
                        useRange={false}
                        value={date}
                        onChange={(val) => setDate(val)}
                        showShortcuts={false}
                      />
                    </div>

                    {selectedValue === "admin" && (
                      <>
                        <div className="relative col-span-6">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"Password"}
                            inputPlaceholder={"Enter  Password"}
                            labelClasses={"!text-base"}
                            inputType={"password"}
                            value={values.password}
                            inputName={"password"}
                            onChange={handleChange}
                            {...formValidate(errors, "password")}
                          />
                        </div>
                        <div className="relative col-span-6">
                          <Input
                            isInputGroup={true}
                            inputGroupIcon={"fa-solid fa-key"}
                            inputGroupPosition={"left"}
                            label={"Confirm Password"}
                            inputPlaceholder={"Enter Confirm Password"}
                            labelClasses={"!text-base"}
                            inputType={"confirmPassword"}
                            value={values.confirmPassword}
                            inputName={"confirmPassword"}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4">
                <div className="relative">
                  <SingleImageUpload
                    label={"Profile Image"}
                    size={"md"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                {/* <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/employees"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={"!bg-white !text-org-theme"}
                /> */}
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Save"}
                  isDisable={loaded ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EditUser;
