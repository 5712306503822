import { apiRequest } from "../util/async/apiUtils";

export const dashboardDetails = async (params) => {
  try {
    const res = await apiRequest("get", `/dashboard`);
    return res?.data;
  } catch (err) {
    throw err;
  }
};
