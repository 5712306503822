import { apiRequest } from "../util/async/apiUtils";

export const loadSiteSettingsDetails = async (setDetails = () => {}, setLoaded = () => {}) => {
  setLoaded(false);

  try {
    const res = await apiRequest("get", `site-setting`);
    const data = res.data;

    if (res?.data?.status === 200) {
      setDetails(res?.data?.data);
      setLoaded(true);
    }
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};
export const updateSetting = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      `site-setting/update`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    const data = res.data;
    return res;
  } catch (err) {
    return err;
  }
};
