import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const HomeAbout = () => {
  const bgRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    // Parallax background
    gsap.to(bgRef.current, {
      y: -100, // More subtle parallax effect
      ease: "power1.out",
      scrollTrigger: {
        trigger: bgRef.current,
        start: "top bottom", 
        scrub: 1,
      },
    });

    // Text animation on scroll
    gsap.fromTo(
      textRef.current,
      { opacity: 0, y: 50 }, // Slightly smoother transition
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power2.out",
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 90%",
          end: "bottom 40%",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gray-50">
      {/* Parallax Background */}
      <div
        ref={bgRef}
        className="absolute inset-0 w-full h-full bg-cover bg-center opacity-75"
        style={{
          backgroundImage: `url('https://accabanquet.com/wp-content/uploads/2021/09/20210818_143637.jpg')`,
        }}
      ></div>

      {/* Content Section */}
      <div className="relative z-10 text-center max-w-4xl mx-auto px-4 py-16">
        <h2
          ref={textRef}
          className="text-6xl font-extrabold text-org-blue drop-shadow-lg tracking-wide"
        >
          Stunning Parallax Design
        </h2>
        <p className="text-2xl text-gray-100 max-w-3xl mt-5 drop-shadow">
          This section animates smoothly as you scroll. With the power of GSAP,
          you can create highly engaging and visually appealing experiences.
        </p>
        <button className="mt-10 px-6 py-3 text-lg font-semibold text-white bg-indigo-600 hover:bg-indigo-800 transition-transform transform hover:scale-105 rounded-lg shadow-lg">
          Get Started
        </button>
      </div>
    </div>
  );
};

export default HomeAbout;
