import React, { useCallback, useEffect, useState } from "react";
import NumberCountAnimation from "../../../components/common/NumberCountAnimation";
import { dashboardDetails } from "../../../services/DashboardService";
import EmployeeList from "../employee/EmployeeList";
import Company from "./Company";
import Employee from "./Employee";

const AdminDashboard = () => {
  const [details, setDetails] = useState("");
  const getDetails = useCallback(() => {
    setDetails({ loading: true, data: {} });
    dashboardDetails({}).then((res) => {
      if (res?.status === 200) {
        setDetails({ loading: false, data: res?.docs });
      } else {
        setDetails({ loading: false, data: {} });
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <div className="flex flex-col gap-4">
      <div className={"relative flex justify-between items-center"}>
        <div className="text-3xl  font-bold">Dashboard</div>
      </div>
      <div
        className={
          details?.loading
            ? "animate-pulse text-gray-300 flex flex-row gap-2"
            : "md:grid lg:flex flex-row gap-2 space-y-4 lg:space-y-0"
        }
      >
        <div className="bg-white shadow-md text-sky-500 rounded-lg p-4 flex justify-between w-full md:col-span-2 h-auto lg:h-28 items-center">
          <div className="flex items-center">
            <i className="fas fa-building text-5xl ml-4"></i>
          </div>
          <div className="flex flex-col items-end mr-8">
            <h2 className="text-lg font-semibold mb-0 lg:mb-2">Total Companies</h2>
            <p className="text-4xl lg:text-5xl font-bold">
              <NumberCountAnimation start={0} end={details?.data?.totalCompanyCount || 0} duration={1000} />
            </p>
          </div>
        </div>

        <div className="bg-white shadow-md text-pink-500 rounded-lg p-4 flex justify-between w-full md:col-span-2 h-auto lg:h-28 items-center">
          <div className="flex items-center ">
            <i className="fa-regular fa-users text-5xl ml-4"></i>
          </div>
          <div className="flex flex-col items-end mr-8">
            <h2 className="text-lg  font-semibold mb-0 lg:mb-2">Total Employees</h2>
            <p className="text-4xl lg:text-5xl font-bold ">
              <NumberCountAnimation start={0} end={details?.data?.totalUserCount || 0} duration={1000} />
            </p>
          </div>
        </div>
        <div className="bg-white text-blue-800 shadow-md rounded-lg p-4 flex justify-between w-full md:col-span-2 h-auto lg:h-28 items-center">
          <div className="flex items-center">
            <i className="fa-solid fa-user-tie text-5xl ml-4"></i>
          </div>
          <div className="flex flex-col items-end mr-8">
            <h2 className="text-lg font-semibold mb-0 lg:mb-2">Total Admins</h2>
            <p className="text-4xl lg:text-5xl font-bold ">
              <NumberCountAnimation start={0} end={details?.data?.totalAdminCount || 0} duration={1000} />
            </p>
          </div>
        </div>

        {/* <div className="bg-white shadow-md rounded-lg p-4 flex lg:flex-col justify-between w-full md:col-span-2 h-auto lg:h-28 items-center">
          <h2 className="text-lg font-semibold mb-0 lg:mb-2">Total Resources</h2>
          <p className="text-xl lg:text-3xl font-bold">
            {dashBoardCount.data?.resourceCount}
          </p>
        </div> */}
      </div>
      <div className=" flex gap-2">
        <div className="w-full xl:w-1/2 p-2 bg-slate-200">
          <div>
            <p className="text-xl font-semibold mb-3">Company List</p>
          </div>
          <div className="relative">
            <Company />
          </div>
        </div>
        <div className="w-full xl:w-1/2 p-2 bg-slate-200">
          <div>
            <p className="text-xl font-semibold mb-3">Employee List</p>
          </div>
          <div className="relative">
            <Employee />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
