import React, { useState, useEffect, useCallback } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";

import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";

import Logo from "../assets/images/logo.png";

const DefaultLayout = ({ isMenuData }) => {
  const navigate = useNavigate();

  const menuData = {
    logo: Logo,
    menu: [
      {
        _id: 1,
        name: "Home",
        link: "/",
        isParent: false,
      },
      {
        _id: 2,
        name: "About Us",
        link: "/",
        isParent: false,
      },
      {
        _id: 3,
        name: "Virtual Tour",
        link: "/",
        isParent: false,
      },
      {
        _id: 4,
        name: "Menu",
        link: "/",
        isParent: true,
        subdata: [
          {
            _id: 1,
            name: "Indian Menu",
            link: "/",
            isParent: false,
          },
          {
            _id: 2,
            name: "Wastern Menu",
            link: "/",
            isParent: false,
          },
          {
            _id: 3,
            name: "Filipino Menu",
            link: "/",
            isParent: false,
          },
        ],
      },
      {
        _id: 5,
        name: "Amenities",
        link: "/",
        isParent: false,
      },
      {
        _id: 6,
        name: "Contact",
        link: "/",
        isParent: false,
      },
      {
        _id: 7,
        name: "Gallery",
        link: "/",
        isParent: false,
      },
    ],
    appDownload: {
      menu: [
        { _id: 1, name: "Play Store", link: "/", image: "PlayStore" },
        { _id: 2, name: "App Store", link: "/", image: "AppStore" },
      ],
    },
  };

  const footerData = {
    logo: Logo,
    footerMenu: {
      title: "Quick Links",
      menu: [
        { _id: 1, name: "Home", link: "/" },
        { _id: 2, name: "About Us", link: "/" },
        { _id: 4, name: "FAQ's", link: "/" },
        { _id: 5, name: "Contact Us", link: "/contact-us" },
      ],
    },
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: "unicorncloths@gmail.com",
          icon: "fa-envelope",
          type: "mailto",
        },
        {
          _id: 2,
          label: "Contact No.",
          data: 748589658,
          icon: "fa-phone-volume",
          type: "tel",
        },
        {
          _id: 3,
          label: "Address",
          data: "Kolkata",
          icon: "fa-location-dot",
          type: "address",
        },
      ],
    },
    footerDownload: {
      title: "App Download",
      menu: [
        {
          _id: 1,
          name: "Play Store",
          // link: settings?.social?.playstore,
          // image: PlayStore,
        },
        {
          _id: 2,
          name: "App Store",
          // link: settings?.social?.appstore,
          // image: AppStore,
        },
      ],
    },
    socialMenuTitle: "Reach us at",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: "settings?.social?.facebook",
        icon: "fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: "/",
        icon: "fa-instagram",
      },
      {
        _id: 3,
        name: "Twitter",
        link: "/",
        icon: "fa-x-twitter",
      },
    ],
    copyright: "Copyright © 2024 ServiceFlows | All Rights Reserved",
  };

  return (
    <>
      <DefaultHeader data={menuData} />
      <ScrollSpy
        scrollThrottle={100}
        useBoxMethod={false}
        updateHistoryStack={false}
        activeClass="active"
      >
        <Outlet />
      </ScrollSpy>
      <DefaultFooter data={footerData} />
    </>
  );
};

export default DefaultLayout;
