import { apiRequest } from "../../util/async/apiUtils";

export const getAdminList = async (params) => {
  try {
    const res = await apiRequest("get", "/user/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const getReportList = async (params) => {
  try {
    const res = await apiRequest("get", "/user/report-to-list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const changeUserStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", "user/status-admin-user", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const deleteUser = async (params) => {
  try {
    const res = await apiRequest("delete", `user/delete/${params?.id}`, {
      body: {},
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const getCompanyList = async (params) => {
  try {
    const res = await apiRequest("get", "/company/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const getPageList = async (params) => {
  try {
    const res = await apiRequest("get", "/page", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addCompany = async (params) => {
  try {
    const res = await apiRequest(
      params.companyId ? "put" : "post",
      params.companyId ? "company/edit" : "company/add",
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const companyDetails = async (params) => {
  try {
    const res = await apiRequest("get", `company/detail/${params?.id}`);
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const addEmployee = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "put" : "post",
      params.id ? "user/edit" : "user/add",
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const employeeDetails = async (params) => {
  try {
    const res = await apiRequest("get", `user/details/${params?.id}`, {
      queries: params,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const getSearchEployeeList = async (params) => {
  try {
    const res = await apiRequest("get", "/user/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
